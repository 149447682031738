import React, { Component } from 'react'
import StatusPageMixin from './StatusPageMixin'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

export class TimedOut extends StatusPageMixin(Component){
  body(){
    return (
      <div>
        <Typography variant='h6'>Confirmation Resent</Typography>
        <br/>
        <Typography variant='body2'>We have resent your verification email.</Typography>
        <br/>
        <Typography variant='body1'>Please follow the instruction on your email to verify your account to continue.</Typography>
        <br/>
        <Button fullWidth variant='contained' color="primary" onClick={() => this.props.history.push('/tokens/resend_confirmation')}>Resend Confirmation</Button>&emsp;
        <Button fullWidth variant='contained' color="accent" onClick={this.goToRoot}>Go Back!</Button>
      </div>
    )
  }
}

export default connect()(TimedOut)