import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { FundCategoryActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    FundCategoryActions.bindActions(this)
  }

  get formObject(){
    return {...this.props.fundCategory, ...this.state.formAttributes}
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Edit Fund Category - ${this.formObject.name || ''}`}/>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <TextField multiline fullWidth member='description'/>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </PageContainer>
}

const styles = {
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({fundCategories}) => fundCategories),
)(Form)