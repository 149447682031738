import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, deepSet, uuid } from 'utils'
import { ActionHeader, DatePicker, PercentageField, LabeledSelect } from 'components'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import * as API from 'api'
import withStyles from 'styles'

export class FundBenchmarkSelector extends Component {

  state = { fundBenchmarks: [] }

  componentDidMount = async () => {
    const { data: fundBenchmarks } = await API.FundBenchmarks.index({
      options: {
        page: { number: 1, size: 2000 },
        order: 'shortName'
      }
    })
    this.setState({ fundBenchmarks })
  }
  get fundBenchmarkFunds() {
    return this.props.value || []
  }

  handleAddFundBenchmark = () => {
    const primary = ((this.fundBenchmarkFunds || []).length === 0)
    this.handleValueChange([
      ...this.fundBenchmarkFunds,
      {
        inceptionDate: new Date(),
        uuid: uuid(),
        fundBenchmark: {},
        primary,
        primaryDaily: primary
      }
    ])
  }

  handleDeleteFundBenchmark = rmIdx => () => {
    this.handleValueChange(this.fundBenchmarkFunds.filter((_, i) => i !== rmIdx))
  }

  handleValueChange = value => {
    this.props.onChange && this.props.onChange({ target: { value } })
  }

  handleFbfChange = (field, updateIdx) => ({ target: { value } }) => {
    this.handleValueChange(
      this.fundBenchmarkFunds.map((fbf, i) => updateIdx === i ?
        deepSet(value, field, this.fundBenchmarkFunds[updateIdx]) : fbf
      )
    )
  }

  handlePrimaryChange = (field) => ({ target: { value } }) => {
    this.handleValueChange(
      this.fundBenchmarkFunds.map((fbf, i) =>
        deepSet(value === `${i}`, field, fbf)
      )
    )
  }

  renderFundBenchmarkFund = ({ id, uuid, initialValue, inceptionDate, primary, fundBenchmark: { id: fundBenchmarkId } }, index) =>
    <ListItem key={id || uuid} className={this.props.classes.fbfListItem}>
      <PercentageField label='Initial Value' value={initialValue} onChange={this.handleFbfChange('initialValue', index)} />
      <DatePicker label='Inception Date' value={inceptionDate} onChange={this.handleFbfChange('inceptionDate', index)} />
      <LabeledSelect label='Benchmark' value={fundBenchmarkId} onChange={this.handleFbfChange('fundBenchmark.id', index)}>
        {
          this.state.fundBenchmarks.map(benchmark =>
            <MenuItem
              key={benchmark.id}
              value={benchmark.id}
            >
              <span className={this.props.classes.shortName}>{benchmark.shortName || '-'}</span>
              {benchmark.name}
            </MenuItem>
          )
        }
      </LabeledSelect>
      <IconButton onClick={this.handleDeleteFundBenchmark(index)}>
        <ClearIcon />
      </IconButton>
    </ListItem>

  render = () => {
    return (<Card className={this.props.classes.fundBenchmarkSelector}>
      <CardContent>
        <ActionHeader title="Benchmarks">
          <IconButton onClick={this.handleAddFundBenchmark}>
            <AddIcon />
          </IconButton>
        </ActionHeader>
        <LabeledSelect fullWidth label="Primary Benchmark" onChange={this.handlePrimaryChange('primary')} value={`${this.fundBenchmarkFunds.findIndex(fbf => fbf.primary)}`}>
          {this.fundBenchmarkFunds.map((fbf, i) => {
            const benchmark = (this.state.fundBenchmarks.find(f => `${f.id}` === `${(fbf.fundBenchmark || {}).id}`) || {})
            return <MenuItem key={i} value={`${i}`}>
              <span className={this.props.classes.shortName}>{benchmark.shortName || '-'}</span>
              {benchmark.name}
            </MenuItem>
          })}
        </LabeledSelect>
        <LabeledSelect fullWidth label="Primary Daily Benchmark" onChange={this.handlePrimaryChange('primaryDaily')} value={`${this.fundBenchmarkFunds.findIndex(fbf => fbf.primaryDaily)}`}>
          {[<MenuItem key={"none"} value={-1}>None</MenuItem>,
          ...this.fundBenchmarkFunds.map((fbf, i) => {
            const benchmark = (this.state.fundBenchmarks.find(f => `${f.id}` === `${(fbf.fundBenchmark || {}).id}`) || {})
            return <MenuItem key={i} value={`${i}`}>
              <span className={this.props.classes.shortName}>{benchmark.shortName || '-'}</span>
              {benchmark.name}
            </MenuItem>
          })]}
        </LabeledSelect>
        <List>
          {this.fundBenchmarkFunds.map(this.renderFundBenchmarkFund)}
        </List>
      </CardContent>
    </Card>)
  }
}

const styles = {
  fundBenchmarkSelector: {
    margin: '10px 0',
    flex: '1 1 400px'
  },
  fbfListItem: {
    padding: 0,
    '& > div': {
      flex: '1 1 120px',
      margin: '0 5px'
    },
    '& :first-child': {
      marginLeft: 0
    },
    '& > :last-child': {
      marginRight: 0
    },
    '& > :nth-child(3)': {
      flex: '1.5 1 180px',
      marginRight: 0
    }
  },
  shortName: {
    margin: "0 5px",
    display: "inline-block",
    fontWeight: "bold",
    background: "#dbdbdb",
    padding: '0 10px 0 10px',
    textAlign: "center",
    borderRadius: "14px",
    float: "left",
    lineHeight: "1.6",
    paddingTop: "1px",
  }
}

export default compose(
  withStyles(styles),
  connect(({ fundBenchmarks }) => fundBenchmarks),
)(FundBenchmarkSelector)