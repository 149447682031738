import React, { PureComponent } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

export class PercentageField extends PureComponent {
  state = { value: '', decimalValue: null }

  renderAdornment = () => {
    return (
      <InputAdornment position="end">%</InputAdornment>
    )
  }

  componentDidMount = () => {
    if (this.props.value || this.props.value === 0)
      this.setState({ value: this.toDisplay(this.props.value) })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value && this.props.value !== this.state.decimalValue) {
      if (this.props.value || this.props.value === 0)
        this.setState({ value: this.toDisplay(this.props.value), decimalValue: this.props.value })
    }
  }

  toDecimal = displayValue => {
    if (displayValue.trim() === "") {
      return null
    }
    let value = displayValue / 100
    if (Number.isNaN(value)) {
      value = null
    }
    return value
  }

  toDisplay = decimalValue => {
    if (decimalValue === null || Number.isNaN(decimalValue) || decimalValue === "")
      return ''
    let convertedValue = decimalValue * 100
    if (!Number.isNaN(convertedValue)) {
      convertedValue = convertedValue.toFixed(Math.max(this.countDecimals(decimalValue) - 2, 0))
    }
    return convertedValue.toString()
  }

  countDecimals = (value) => {
    var number = value.toString()
    return (number.length - 1) - number.indexOf('.')
  }

  handleChange = event => {
    const value = this.toDecimal(event.target.value)
    if (value !== null || event.target.value === "") {
      this.props.onChange && this.props.onChange({ target: { value } })
      this.setState({ value: event.target.value, decimalValue: value })
    }
  }

  render = () => {
    return (
      <TextField {...this.props} value={this.state.value} onChange={this.handleChange}
        InputProps={{ ...this.props.inputProps, endAdornment: this.renderAdornment(), }} />
    )
  }
}

export default PercentageField