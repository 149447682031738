import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FileImportActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, LabeledCheckbox  } from 'components'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FileImportIcon from '@material-ui/icons/InsertDriveFile'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { compose, errorStringsFromError, userFriendlyDate } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import withStyles from 'styles'
import * as API from 'api'
import { Authorization } from 'utils'

export class List extends Component{

  constructor(props){
    super(props)
    FileImportActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1,
    fileUploadDialogOpen: false,
    dragging: false,
    loading: false,
    firstChild: {}
  }

  dependsOn(){
    return this.loadFileImports()
  }

  loadFileImports = () => {
    return this.actions.index({
      page: this.props.page,
      fields: {file_imports: 'name,fileUrlPath,importedAt'}
    })
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.fileImports !== prevProps.fileImports){
      setTimeout(() => this.setState({firstChild: this.props.fileImports[0] || {}}), 100)
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showFileImport = id => () => {
    this.props.history.push(`/file_imports/${id}`)
  }

  editFileImport = id => event => {
    this.props.history.push(`/file_imports/${id}/edit`)
    event.stopPropagation()
  }

  deleteFileImport = id => event => {
    this.actions.destroy({id})
                .then(this.loadFileImports)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get fileImports(){
    return this.props.fileImports
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadFileImports()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  get ignoreFileUploadErrors(){
    return this.state.ignoreFileUploadErrors
  }

  handleCloseFileUploadDialog = () => {
    this.setState({fileUploadDialogOpen: false, fileUploadError: undefined, fileUploadWarnings: undefined})
  }

  handleOpenFileUploadDialog = () => {
    this.setState({fileUploadDialogOpen: true})
  }

  handleDrop = async event => {
    event.preventDefault();
    event.stopPropagation()
    const file = event.dataTransfer.items ? event.dataTransfer.items[0].getAsFile() : event.dataTransfer.files[0]
    this.handleUploadFile(file)
  }

  handleUploadFile = async file => {
    try{
      this.setState({loading: true})
      const { meta } = await API.FileImports.create({file, ignoreErrors: this.state.ignoreFileUploadErrors})
      this.loadFileImports()
      if(meta && meta.warnings && meta.warnings.length){
        this.setState({fileUploadWarnings: meta.warnings})
        this.actions.snackbar.show("File imported with warnings")
      }else{
        this.handleCloseFileUploadDialog()
        this.actions.snackbar.show("File imported successfully")
      }
    }catch(err){
      this.setState({fileUploadError: err})
      this.actions.snackbar.show("File import failed")
    }finally{
      this.setState({dragging: false, loading: false})
    }
  }

  handleSetIgnoreErrors = ({target: { checked }}) => {
    this.setState({ignoreFileUploadErrors: checked})
  }

  handleDragOver = event => {
    event.preventDefault()
    this.setState({fileUploadError: undefined, fileUploadWarnings: undefined,  dragging: true})
  }

  handleDragEnter = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDownloadFile = url => () =>
    window.open(url, "_blank")

  handleDragLeave = event => {
    this.setState({dragging: false})
  }

  handleDragExit = event => {
    this.setState({dragging: false})
  }

  handleFileSelected = ({target: { files: { "0": file }}}) => {
    this.fileInput.value = null
    this.handleUploadFile(file)
  }

  handleSetFileInputRef = fileInput => {
    this.fileInput = fileInput
  }

  handleToggleFileInput = () => {
    this.fileInput.click()
  }

  handleTriggerMailScrape = () => API.FileImports.scrape().then(() => this.actions.snackbar.show('Import Queued'))

  renderFileImportListItem = ({id, fileName, fileUrlPath, importedAt}) => {
    const date = importedAt ? userFriendlyDate(importedAt) : ''
    return (
      <ListItem className={this.props.classes({firstChild: id === this.state.firstChild.id})} button onClick={this.showFileImport(id)} key={id}>
        <ListItemIcon>
          <FileImportIcon />
        </ListItemIcon>
        <ListItemText primary={fileName} secondary={date}/>
        <ListItemSecondaryAction>
          <IconButton onClick={this.handleDownloadFile(fileUrlPath)}><DownloadIcon/></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderDropZone = () =>
  <Fragment>
    <input style={{display: 'none'}} ref={this.handleSetFileInputRef} type='file' id="dropzone-file-input" onChange={this.handleFileSelected}/>
    <Button
      className={
      this.props.classes({
        dragging: this.state.dragging,
        loading: this.state.loading,
        dropZone: true
      })
    } onDrop={this.handleDrop}
      onDragEnter={this.handleDragEnter}
      onDragOver={this.handleDragOver}
      onDragLeave={this.handleDragLeave}
      onDragExit={this.handleDragExit}
      onClick={this.handleToggleFileInput}
    >
      <div className={this.props.classes.ball}/>
      {(this.state.dragging || this.state.loading) ? "": <span>Drop or Click here</span>}
    </Button>
  </Fragment>

  renderFileUploadDialog = () =>
    <Dialog open={this.state.fileUploadDialogOpen} onClose={this.handleCloseFileUploadDialog}>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent className={this.props.classes.dialogContent}>
        {this.renderDropZone()}

        <ErrorBanner>
        {
          this.state.fileUploadError && this.state.fileUploadError.meta &&
          errorStringsFromError(this.state.fileUploadError.meta.messages)
        }
        </ErrorBanner>
        <ErrorBanner warning>
        {
          this.state.fileUploadWarnings &&
          ["File Imported with warnings"].concat(errorStringsFromError(this.state.fileUploadWarnings))
        }
        </ErrorBanner>

      </DialogContent>
      <DialogActions className={this.props.classes.dialogActions}>
        <LabeledCheckbox label='Skip rows with errors' checked={!!this.state.ignoreFileUploadErrors} onChange={this.handleSetIgnoreErrors}/>
        <Button variant='contained' onClick={this.handleCloseFileUploadDialog} color='secondary' style={{alignSelf: 'flex-end', marginTop: 10}}>Close</Button>
      </DialogActions>
    </Dialog>

  render = () =>
    <PageContainer>
      <ActionHeader title="File Imports">
        {
          Authorization.admin &&
            <>
              <Button style={{marginRight: 5}} color="primary" variant="contained" onClick={this.handleTriggerMailScrape}>Import Mail</Button>
              <Button color="primary" variant="contained" onClick={this.handleOpenFileUploadDialog}>Add</Button>
            </>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      <MuiList className={this.props.classes.fileImportList} dense>
        {this.fileImports.map(this.renderFileImportListItem)}
      </MuiList>
      {this.renderFileUploadDialog()}

      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = ({palette}) => ({
  firstChild: {
    backgroundColor: '#c3d3f3 !important',
  },
  fileImportList: {
    '& li > div': {
      transition: 'background-color 0.5s linear'
    }
  },
  dropZone: {
    height: 250,
    minWidth: 250,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px dashed silver',
    display: 'flex',
    cursor: 'pointer',
    padding: 10,
    '&:hover':{
      background: 'rgba(0, 0, 0, 0.1)'
    }
  },
  ball: {},
  loading: {
    '& $ball': {
      width: 30,
      height: 30,
      borderRadius: 30,
      animation: 'ballPulse 2s ease infinite'
    }
  },
  '@keyframes ballPulse': {
    '0%': {
      backgroundColor: palette.primary.main,
      width: 30,
      height: 30,
    },
    '50%': {
      backgroundColor: palette.secondary.main,
      width: 70,
      height: 70,
      borderRadius: 70,
    },
    '100%': {
      backgroundColor: palette.primary.main,
      height: 30,
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogActions: {
    justifyContent: "space-between",
    padding: "0 30px 10px 30px",
    marginTop: "-20px",
  },
  dragging: {
    background: '#efefef',
    border: '3px solid silver'
  }
})

export default compose(
  Dependent({loader: true}),
  connectQueryString('fileImports'),
  withStyles(styles),
  connect(({fileImports}) => fileImports)
)(List)