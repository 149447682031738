import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MetricTypeActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import MetricTypeIcon from '@material-ui/icons/ScatterPlot'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { Authorization } from 'utils'

export class List extends Component{

  constructor(props){
    super(props)
    MetricTypeActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadMetricTypes()
  }

  loadMetricTypes = () => {
    return this.actions.index({
      page: this.props.page,
      filter: { category: "fund_returns" },
      fields: {metric_types: 'displayName'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showMetricType = id => () => {
    this.props.history.push(`/metric_types/${id}`)
  }

  editMetricType = id => event => {
    this.props.history.push(`/metric_types/${id}/edit`)
    event.stopPropagation()
  }

  deleteMetricType = id => event => {
    this.actions.destroy({id})
                .then(this.loadMetricTypes)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get metricTypes(){
    return this.props.metricTypes
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadMetricTypes()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderMetricTypeListItem = ({id, displayName, custom}) =>
    <ListItem button onClick={this.showMetricType(id)} key={id}>
      <ListItemIcon>
        <MetricTypeIcon />
      </ListItemIcon>
      <ListItemText primary={displayName}/>
      <ListItemSecondaryAction>
        {
          Authorization.admin &&
          <>
            <IconButton onClick={this.editMetricType(id)}><EditIcon/></IconButton>
            <IconButton disabled={!custom} onClick={this.deleteMetricType(id)}><DeleteIcon/></IconButton>
          </>
        }
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <ActionHeader title="Metric Types">
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push('/metric_types/new')}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.metricTypes.map(this.renderMetricTypeListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('metricTypes'),
  connect(({metricTypes}) => metricTypes)
)(List)