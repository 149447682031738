import React, { Component } from 'react'
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd'
import withStyles from 'styles'
import { escapeRegex } from 'utils'
import { Draggable } from 'react-beautiful-dnd'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { AutoSuggest } from 'components'

export class ColumnPicker extends Component{

  static propTypes = {

  }

  state = {
    shouldShowColumnSelect: false
  }

  handleDragEng = ({source, destination},...args) => {
    const reordered = this.props.value.slice()
    reordered.splice(destination.index, 0, reordered.splice(source.index,1)[0])
    this.props.onChange(reordered)
  }

  handleRemove = rIdx => () => {
    this.props.onChange([...this.props.value.slice(0, rIdx), ...this.props.value.slice(rIdx + 1)])
  }

  handleShowColumnSelect = () => {
    this.setState({shouldShowColumnSelect: true})
  }

  handleResetColumns = () => {
    this.props.onChange([])
  }

  handleProvideColumns = (text, callback) => {
    const parts = text.split(/\s+/g)
    callback(this.props.availableColumns.filter(av => (av.available && parts.every(part => av.name.toLowerCase().match(escapeRegex(part.toLowerCase()))))).sort((a, b) => a.sequence > b.sequence ? 1 : -1).slice(0,15))
  }

  handleSuggestionSelected = suggestionSelected => {
    this.setState({shouldShowColumnSelect: false})
    if(suggestionSelected && suggestionSelected.target && suggestionSelected.target.value){
      this.props.onChange([...this.props.value, suggestionSelected.target.value])
    }
  }

  handleSaveAsFavourite = () => {
    if (this.props.onSaveAsFavourite) { this.props.onSaveAsFavourite() }
  }

  columnLabelProvider = ({name}) => name
  /**
   * Dropping re-orders columns (and writes to Q string)
   * Picking up changes color
   * Button allows you to search and pick columns
   * Cross removes column
   */
  render = () =>
    <>
      <div className={this.props.classes.menuHead}>
        <Typography variant='h4'>Columns</Typography>
        <div className={this.props.classes.altActions}>
          <Button variant='contained' size='small' color='secondary' onClick={this.handleResetColumns}>Reset to Defaults</Button>
          <Button variant='contained' size='small' color='primary' onClick={this.handleSaveAsFavourite}>Save as Favourite</Button>
        </div>
        {
          this.state.shouldShowColumnSelect ?
          <AutoSuggest
            fullWidth
            onSuggestionsFetchRequested={this.handleProvideColumns}
            labelProvider={this.columnLabelProvider}
            onChange={this.handleSuggestionSelected}
            onBlur={this.handleSuggestionSelected.bind(null)}
            autoFocus
          /> :
          <Button fullWidth onClick={this.handleShowColumnSelect} variant='contained' size='small'>Add Column</Button>
        }
      </div>
      <DragDropContext onDragEnd={this.handleDragEng}>
        <Droppable style={{ transform: "none" }} droppableId="picker">
          {(provided, snapshot) => (
            <ul
              ref={provided.innerRef}
              className={this.props.classes.list}
               {...provided.droppableProps}
            >
              {
                this.props.value.map((column, idx) =>
                  <Draggable  style={{ transform: "none" }} key={`${column.id}-${idx}`} draggableId={`${column.id}-${idx}`} index={idx}>
                    {(provided, snapshot) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={this.props.classes.listItem}
                        ref={provided.innerRef}
                      >
                        {column.name}
                        {provided.placeholder}
                        <IconButton className={this.props.classes.removeIcon} size='small' onClick={this.handleRemove(idx)}>
                          <CloseIcon fontSize='small'/>
                        </IconButton>
                      </li>
                    )}
                  </Draggable>
                )
              }
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
}

const styles = theme => ({
  menuHead: {
    width: 300,
    flex: '0 0 auto',
    padding: 20,
    margin: 0,
    background: theme.palette.primary.light,
    '& > h4': {
    }
  },
  altActions: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 10,
    '& > button': {
      flex: 1
    }
  },
  removeIcon: {
    padding: 5
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  listItem: {
    userSelect: 'none',
    padding: '5px 10px',
    background: 'white',
    marginBottom: -1,
    border: '1px solid silver',
    cursor: 'pointer',
    display: 'flex',
    zIndex: 1500,
    justifyContent: 'space-between',
    '&:hover': {
      background: theme.palette.primary.light
    }
  }
})
export default withStyles(styles)(ColumnPicker)