export function debounce(fnc, wait, context){
  let timer, promise, resolve
  return function () {
    promise = promise || new Promise(r => resolve = r)
    clearTimeout(timer)
    timer = setTimeout(() => {
      resolve(fnc.bind(context)(...arguments))
      promise = null
    }, wait || 250)
    return promise
  }
}

export function throttle(fnc, wait, context){
  let isQueued = false
  let promise, resolve, result

  return function () {
    if(isQueued) return promise
    isQueued = true
    promise = new Promise(r => resolve = r)
    result = fnc.bind(context)(...arguments)
    setTimeout(() => {
      resolve(result)
      isQueued = false
      promise = null
    }, wait || 250)
    return promise
  }
}

export function timeout(time=0){
  return new Promise(resolve => setTimeout(resolve, time))
}
