import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'
import { capitalize } from 'utils'
import pluralize from 'pluralize'
/**
*
* CrudActionSetBase
*
* This base class implements common CRUD functionality for a single API resource.
* It can from a single string, implement the action creators and reducers for:
* - index
* - create
* - update
* - destroy
* - show
* - goToPage
*
* The CrudActionSetBase mixin uses convention to minimise the need of configuration.
* At its simplest, it can construct a completely functioning actionset from a single string.
*
* E.g.
*
* class extends CrudActionSetBase('account'){}
*
* When using the above format a number of assumptions are made.
* 1. The given string is the singular form of the entity name
* 2. The plural form can be given by appending an s (if this is not the case, the second argument allows an alternate plural form to be given)
* 3. The API Resource is a member of the API object, named the same as the pural form (see assumption 2.) If this is not the case an alternate apiResource can be given in the second argument
* 4. The base class we hope to mixin to is the ActionSet class. (You may want to provide an alternate superclass as a third argument if you are stacking multiple mixins)
*
* Note that:
*   Any of the defined actions can be overwritten in the subclass
*   Any members, or all of the initial state can be overwritten in the subclass
*/
export default function CrudActionSetBase(
  apiName, // The singular form of the entity name
  {
    // The APIResource object to use for API requests.
    // Assume it implements:
    //   #index
    //   #create
    //   #update
    //   #destroy
    //   #show
    apiResource = API[capitalize(pluralize(apiName))],
    term: {
      plural   = pluralize(apiName), // The plural form of the entity name. Used to store results of index in the corresponding state
      singular = apiName       // The plural form of the entity name. Used to store results of show, create.etc in the corresponding state
    }={}
  }={},
  superclass = ActionSet
){
  return (
    class extends superclass{

      static constantsMiddleware = [
        requestServiceConstantsMiddleware
      ]

      static initialState = {
        page:        1,
        totalPages:  1,
        [plural]:    [],
        [singular]:  {},
        requests:    [],
        errors: {
          index:   null,
          create:  null,
          update:  null,
          destroy: null,
          show:    null
        }
      }

      static index(creator, reducer, constants){

        constants.defineRequestConstants()

        creator(({page: targetPage, clearExisting=false, params={}, fields=null, include=null, filter=null, order=null, pageSize=undefined }={}) => (dispatch, getState) => {
          let page = targetPage === undefined ? getState()[plural].page : targetPage
          return dispatch({
            type: constants.ACTION,
            promise: apiResource.index({...params, options: {page: { number: page, size: pageSize }, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
            payload: { page, clearExisting }
          })
        })

        reducer({
          [constants.REQUEST]: (state, { requestPayload: { page, clearExisting }, request }) => {
            return {...state, page, ...clearExisting && {[plural]:[]}, requests: [...state.requests, request ], errors: this.initialState.errors }
          },
          [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages, page, ...meta }} }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            return {...state, requests, [plural]: data, [plural + 'TotalPages']: totalPages, [plural + 'Meta']: meta, totalPages, page }
          },
          [constants.FAILURE]: (state, { request, error }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            const errors = {...state.errors, index: error }
            return {...state, requests, errors }
          }
        })
      }

      static create(creator, reducer, constants){

        constants.defineRequestConstants()

        creator((item, {clearExisting, ...options}={ clearExisting: false}) => {
          return {
            type: constants.ACTION,
            promise: apiResource.create({...item, options}),
            payload: { clearExisting }
          }
        })

        reducer({
          [constants.REQUEST]: (state,{ requestPayload: { clearExisting }={}, request }) => {
            return {...state, ...clearExisting && {[singular]: this.initialState[singular] }, requests: [...state.requests, request ], errors: {...state.errors, create: null }}
          },
          [constants.SUCCESS]: (state, { request, result: { data } }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            return {...state, requests, [singular]: data }
          },
          [constants.FAILURE]: (state, { request, error }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            const errors = {...state.errors, create: error }
            return {...state, requests, errors }
          }
        })
      }

      static update(creator, reducer, constants){

        constants.defineRequestConstants()

        creator((item, options={}) => {
          return {
            type: constants.ACTION,
            promise: apiResource.update({...item, options})
          }
        })

        reducer({
          [constants.REQUEST]: (state, { request }) => {
            return {...state, requests: [...state.requests, request ], errors: {...state.errors, update: null }}
          },
          [constants.SUCCESS]: (state, { request, result: { data } }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            return {...state, requests, [singular]: data}
          },
          [constants.FAILURE]: (state, { request, error }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            const errors = {...state.errors, update: error }
            return {...state, requests, errors }
          }
        })
      }

      static destroy(creator, reducer, constants){

        constants.defineRequestConstants()

        creator(item => {
          return {
            type: constants.ACTION,
            promise: apiResource.destroy(item)
          }
        })

        reducer({
          [constants.REQUEST]: (state, { request }) => {
            return {...state, requests: [...state.requests, request ], errors: {...state.errors, destroy: null }}
          },
          [constants.SUCCESS]: (state, { request, result: { data } }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            return {...state, requests, [singular]: this.initialState[singular] }
          },
          [constants.FAILURE]: (state, { request, error }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            const errors = {...state.errors, destroy: error }
            return {...state, requests, errors }
          }
        })
      }



      static set(creator, reducer, constants){

        constants.defineRequestConstants()

        creator(object => {
          return {
            type: constants.ACTION,
            payload: object
          }
        })

        reducer({
          [constants.ACTION]: (state, object) => {
            return {...state, [singular]: object || this.initialState[singular] }
          }
        })
      }

      static show(creator, reducer, constants){

        constants.defineRequestConstants()

        creator((id,{fields=null, include=null,...params}={}) => {
          return {
            type: constants.ACTION,
            promise: apiResource.show({id, options: { ...fields && {fields}, ...include && {include}, ...params }})
          }
        })

        reducer({
          [constants.REQUEST]: (state, { request }) => {
            return {...state, [singular]: this.initialState[singular], requests: [...state.requests, request ], errors: {...state.errors, show: null, update: null }}
          },
          [constants.SUCCESS]: (state, { request, result: { data } }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            return {...state, requests, [singular]: data }
          },
          [constants.FAILURE]: (state, { request, error }) => {
            const requests = state.requests.filter(existingRequest => (existingRequest !== request))
            const errors = {...state.errors, show: error }
            return {...state, requests, errors }
          }
        })
      }
    }
  )
}