import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import { Checkbox } from '@material-ui/core'
import { compose, deepSet } from 'utils'
import withStyles from 'styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import { ActionHeader } from 'components'
import moment from 'moment'

export class FundColumnSetup extends Component {
  state = {}

  componentDidUpdate = (prevProps) => {
    if (this.props.custodians !== prevProps.custodians || prevProps.fundBenchmarkFunds !== this.props.fundBenchmarkFunds) {
      this.handleValueChange(this.listAllColumns(true))
    }
  }

  get fundColumns() {
    return this.props.value || []
  }

  get custodians(){
    return this.props.custodians || []
  }

  handleValueChange = value => {
    this.props.onChange && this.props.onChange({ target: { value } })
  }

  handleColumnChange = (field, updateIdx) => ({ target: { checked } }) => {
    const col = this.fundColumns[updateIdx]
    const fields = [field]
    if (field === "available" && !checked) { fields.push("isDefault") }
    if (field === "isDefault" && checked) { fields.push("available") }
    this.handleValueChange(
      this.fundColumns.map((fc, i) => {
        const dmtMatch = dmt => `${dmt.id}` === `${fc.derivedMetricTypeId}`
        for (const field of fields) {
          if ((col.expandable && fc.expandedKey === col.expandedKey)) {
            console.log([fc.derivedMetricTypeId, this.props.derivedMetricTypes])
            if (!checked || field !== "isDefault" || !fc.derivedMetricTypeId || this.props.derivedMetricTypes.find(dmtMatch).isDefault) {
              fc = deepSet(checked, field, fc)
            }
          }
          if (!col.expandable && updateIdx === i)
            fc = deepSet(checked, field, fc)
        }
        return fc
      })
    )
  }

  findExistingColumn = (custodianId, metricTypeId, derivedMetricTypeId, fundBenchmarkFundId, fundBenchmarkFundUuid) => {
    return this.fundColumns.find(e =>
      `${e.custodianId}` === `${custodianId}`
      && `${e.metricTypeId}` === `${metricTypeId}`
      && `${e.derivedMetricTypeId}` === `${derivedMetricTypeId}`
      && `${e.fundBenchmarkFundId || null}` === `${fundBenchmarkFundId || null}`
      && `${e.fundBenchmarkFundUuid || null}` === `${fundBenchmarkFundUuid || null}`
    )
  }

  listAllColumns = (mapExisting) => {
    let columns = []
    let counter = 0;
    const bySeq = (a, b) => (a.sequence > b.sequence) ? 1 : -1
    const bySeqCustom = (a, b) => (b.custom <= a.custom && a.sequence > b.sequence) ? 1 : -1
    this.custodians.forEach(custodian => {
      this.props.metricTypes.filter(mt => mt.category === 'fund_returns').sort(bySeqCustom).forEach(metricType => {
        const existing = mapExisting ? this.findExistingColumn(custodian.id, metricType.id, null, null, null) : null
        const expandedKey = metricType.custom ? `custom_${custodian.id}` : `expanded_${custodian.id}_${metricType.id}__`
        columns.push({
          custodianId: custodian.id,
          metricTypeId: metricType.id,
          derivedMetricTypeId: null,
          fundBenchmarkFundUuid: null,
          expandable: metricType.annualise,
          isDefault: metricType.isDefault,
          available: true,
          expandedKey,
          ...existing,
          sequence: counter++,
        })
        if (metricType.annualise) {
          this.props.derivedMetricTypes.filter(dmt => dmt.category === 'fund_returns').sort(bySeq).forEach(derivedMetricType => {
            const existing = mapExisting ? this.findExistingColumn(custodian.id, metricType.id, derivedMetricType.id, null, null) : null
            columns.push({
              custodianId: custodian.id,
              metricTypeId: metricType.id,
              derivedMetricTypeId: derivedMetricType.id,
              expandable: false,
              fundBenchmarkFundUuid: null,
              isDefault: metricType.isDefault && derivedMetricType.isDefault,
              available: true,
              expandedKey,
              ...existing,
              sequence: counter++,
            })
          })
        }
      })
    })
    ;(this.props.fundBenchmarkFunds || []).forEach(fundBenchmarkFund => {
      this.props.metricTypes.filter(mt => mt.category === 'benchmark_points').sort(bySeq).forEach(metricType => {
        const existing = mapExisting ? this.findExistingColumn(null, metricType.id, null, fundBenchmarkFund.id, fundBenchmarkFund.uuid) : null
        const expandedKey = `expanded__${metricType.id}__${fundBenchmarkFund.id}`
        columns.push({
          custodianId: null,
          metricTypeId: metricType.id,
          derivedMetricTypeId: null,
          fundBenchmarkFundId: fundBenchmarkFund.id,
          fundBenchmarkFundUuid: fundBenchmarkFund.uuid,
          expandable: metricType.annualise,
          isDefault: metricType.isDefault,
          available: true,
          expandedKey,
          ...existing,
          sequence: counter++,
        })
        if (metricType.annualise) {
          this.props.derivedMetricTypes.filter(dmt => dmt.category === 'benchmark_points').sort(bySeq).forEach(derivedMetricType => {
            const existing = mapExisting ? this.findExistingColumn(null, metricType.id, derivedMetricType.id, fundBenchmarkFund.id, fundBenchmarkFund.uuid) : null
            columns.push({
              custodianId: null,
              metricTypeId: metricType.id,
              derivedMetricTypeId: derivedMetricType.id,
              expandable: false,
              fundBenchmarkFundId: fundBenchmarkFund.id,
              fundBenchmarkFundUuid: fundBenchmarkFund.uuid,
              isDefault: metricType.isDefault && derivedMetricType.isDefault,
              available: true,
              expandedKey,
              ...existing,
              sequence: counter++,
            })
          })
        }
      })
    })
    return columns
  }

  renderFundColumns = () => {
    const custodians = this.custodians.reduce((custodians, {id, name}) => ({...custodians,  [id]: name }), {})
    const metricTypes = (this.props.metricTypes || []).reduce((metricTypes, {id, displayName}) => ({...metricTypes,  [id]: displayName }), {})
    const derivedMetricTypes = (this.props.derivedMetricTypes || []).reduce((derivedMetricTypes, {id, displayName}) => ({...derivedMetricTypes,  [id]: displayName }), {})
    const fundBenchmarks = (this.props.fundBenchmarks || []).reduce((fundBenchmarks, {id, name}) => ({...fundBenchmarks,  [id]: name }), {})
    const fundBenchmarkFunds = (this.props.fundBenchmarkFunds || []).reduce((fundBenchmarkFunds, {id, uuid, fundBenchmark, inceptionDate}) => ({
      ...fundBenchmarkFunds,
      [id || uuid]: `${fundBenchmarks[fundBenchmark.id] || ''} (${moment(inceptionDate).format('DD/MM/YYYY')})`
    }), {})


    const columns = this.fundColumns.length > 0 && !this.fundColumns[0].expandedKey ? [] : this.fundColumns
    return columns.map((col, idx) => {
      const firstCustom = col.expandedKey.startsWith("custom") && !columns[idx - 1].expandedKey.startsWith("custom")
      const visible = ((!col.derivedMetricTypeId && !col.expandedKey.startsWith("custom")) || !!this.state[col.expandedKey])
      return (firstCustom || visible) &&
        <Fragment key={`${col.custodianId}_${col.metricTypeId}_${col.derivedMetricTypeId}_${col.fundBenchmarkFundId}`}>
          {firstCustom &&
            <TableRow>
              <TableCell>
                <IconButton onClick={() => { this.setState({ [col.expandedKey]: !this.state[col.expandedKey] }) }} className={this.props.classes.noPadding}>
                  {!!this.state[col.expandedKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </TableCell>
              <TableCell>
                {!!col.custodianId ? custodians[col.custodianId] : fundBenchmarkFunds[col.fundBenchmarkFundId || col.fundBenchmarkFundUuid]}
              </TableCell>
              <TableCell>User Defined Columns</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          }
          {visible &&
            <TableRow>
              <TableCell>
                {col.expandable && <IconButton onClick={() => { this.setState({ [col.expandedKey]: !this.state[col.expandedKey] }) }} className={this.props.classes.noPadding}>
                  {!!this.state[col.expandedKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>}
              </TableCell>
              <TableCell>
                {!!col.custodianId ? custodians[col.custodianId] : fundBenchmarkFunds[col.fundBenchmarkFundId || col.fundBenchmarkFundUuid]}
              </TableCell>
              <TableCell>
                {metricTypes[col.metricTypeId]}
              </TableCell>
              <TableCell>
                {derivedMetricTypes[col.derivedMetricTypeId]}
              </TableCell>
              <TableCell>
                <Checkbox className={this.props.classes.noPadding} onChange={this.handleColumnChange("isDefault", col.sequence)} checked={col.isDefault} />
              </TableCell>
              <TableCell>
                <Checkbox className={this.props.classes.noPadding} onChange={this.handleColumnChange("available", col.sequence)} checked={col.available} />
              </TableCell>
            </TableRow>}
        </Fragment>
    })
  }

  render = () => {
    return <div>
      <ActionHeader title="Columns" />
      <Card className={this.props.classes.columnsCard}>
        <Table>
          <TableBody>
            <TableRow className="header">
              <TableCell></TableCell>
              <TableCell><b>Custodian / Benchmark</b></TableCell>
              <TableCell><b>Metric</b></TableCell>
              <TableCell><b>Sub-Metric</b></TableCell>
              <TableCell><b>Default</b></TableCell>
              <TableCell><b>Available</b></TableCell>
            </TableRow>
            {this.renderFundColumns()}
          </TableBody>
        </Table>
      </Card>
    </div>
  }
}

const styles = {
  columnsCard: {
    '& tr:not(.header)': {
      paddingTop: '0',
      paddingBottom: '0',
      height: '32px',
    },
    '& td': {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  noPadding: {
    padding: '0',
  },
}

export default compose(
  withStyles(styles),
  connect(({ fundBenchmarks }) => fundBenchmarks),
  connect(({ metricTypes }) => metricTypes),
  connect(({ derivedMetricTypes }) => derivedMetricTypes),
)(FundColumnSetup)