import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FundBenchmarkActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, SearchCombo } from 'components'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import BenchmarkIcon from '@material-ui/icons/MultilineChart'
import EditIcon from '@material-ui/icons/Edit'
import CompositionIcon from '@material-ui/icons/Settings'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { Authorization } from 'utils'
import withStyles from 'styles'

export class List extends Component{

  constructor(props){
    super(props)
    FundBenchmarkActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.query !== this.props.query) {
      this.loadFundBenchmarks()
    }
  }

  dependsOn(){
    return this.loadFundBenchmarks()
  }

  loadFundBenchmarks = () => {
    return this.actions.index({
      page: this.props.page,
      filter: this.props.filter,
      order: 'shortName',
      fields: {fund_benchmarks: 'name,shortName'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showFundBenchmark = id => () => {
    this.props.history.push(`/fund_benchmarks/${id}`)
  }

  showBenchmarkComposition = id => () => {
    this.props.history.push(`/fund_benchmarks/${id}/composition`)
  }

  editFundBenchmark = id => event => {
    this.props.history.push(`/fund_benchmarks/${id}/edit`)
    event.stopPropagation()
  }

  deleteFundBenchmark = id => event => {
    this.actions.destroy({id})
                .then(this.loadFundBenchmarks)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get fundBenchmarks(){
    return this.props.fundBenchmarks
  }

  get filter() {
    const { filter } = this.props
    return filter
  }

  handleFilterChange = filter => {
    this.props.onFilterChange(filter)
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadFundBenchmarks()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderFundBenchmarkListItem = ({id, name, shortName}) =>
    <ListItem button onClick={this.showFundBenchmark(id)} key={id}>
      <ListItemIcon>
        <BenchmarkIcon />
      </ListItemIcon>
      <ListItemText primary={<>
        <span className={this.props.classes.shortName}>{shortName || '-' }</span>
        <span className={this.props.classes.fullName}>{name}</span>
      </>}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.showBenchmarkComposition(id)}><CompositionIcon/></IconButton>
        {
          Authorization.admin &&
          <>
            <IconButton onClick={this.editFundBenchmark(id)}><EditIcon/></IconButton>
            <IconButton onClick={this.deleteFundBenchmark(id)}><DeleteIcon/></IconButton>
          </>
        }
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearchCombo = () =>
    <SearchCombo onFilterChange={this.handleFilterChange} filter={this.filter}/>

  render = () =>
    <PageContainer>
      <ActionHeader title="Fund Benchmarks">
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push('/fund_benchmarks/new')}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}  startAdornment={this.renderSearchCombo()}/>
      <MuiList dense>
        {this.fundBenchmarks.map(this.renderFundBenchmarkListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = theme => ({
  fullName: {
    display: 'inline-block'
  },
  shortName: {
    margin: "0 5px",
    display: "inline-block",
    fontWeight: "bold",
    background: "#dbdbdb",
    minWidth: "80px",
    padding: '0 10px 0 10px',
    textAlign: "center",
    borderRadius: "14px",
    float: "left",
    lineHeight: "1.6",
    paddingTop: "1px",
  }
})

export default compose(
  Dependent({loader: true}),
  connectQueryString('fundBenchmarks'),
  connect(({fundBenchmarks}) => fundBenchmarks),
  withStyles(styles),
)(List)