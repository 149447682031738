import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { IndexActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { CommentsList } from 'components'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    IndexActions.bindActions(this)
  }

  get id(){
    return this.props.match.params.id
  }

  dependsOn() {
    if (this.editMode) {
      return this.actions.show(this.objectId, { include: 'comments,comments.user' })
    } else {
      return this.actions.set()
    }
  }

  handleDeleteComment = (commentId) => {
    return this.actions.update(
      {id: this.id, comments_attributes: [{id: commentId, _destroy: true}] },
      { include: 'comments,comments.user' }
    )
  }

  handleUpdateComment = (commentId, content) => {
    return this.actions.update(
      {id: this.id, comments_attributes: [{id: commentId, content}] },
      { include: 'comments,comments.user' }
    )
  }

  get formObject() {
    const commentsAttributes = []
    if(this.newComment) {
      commentsAttributes.push({content: this.newComment})
    }

    return {...this.props.index, ...this.state.formAttributes, 'comments_attributes': commentsAttributes }
  }

  get newComment() {
    return this.state.newComment
  }

  newCommentChangedHandler = (event) => {
    this.setState({newComment: event.target.value})
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Edit Index - ${this.formObject.name || ''}`}/>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <TextField fullWidth member='code'/>
          <TextField fullWidth member='currency'/>
          <TextField fullWidth multiline={true} rows={4} onChange={this.newCommentChangedHandler} label='New comment' value={this.newComment} />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
      {
        this.editMode &&
        <CardContent>
          <ActionHeader title="Comments" />
          <CommentsList comments={this.props.index.comments} onDeleteComment={this.handleDeleteComment} onUpdateComment={this.handleUpdateComment} />
        </CardContent>
      }
    </PageContainer>
}

const styles = {
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({indices}) => indices),
)(Form)