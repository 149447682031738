import ActionSet from './ActionSet'

export class SnackbarActionSet extends ActionSet{

  static initialState = {
    message: ''
  }

  static show(creator, reducer, constants){
    creator(message => {
      return {
        type: constants.ACTION,
        payload: message
      }
    })

    reducer({
      [constants.ACTION]: (state, message) => {
        return {...state, message}
      }
    })
  }

  static clear(creator, reducer, constants){
    creator(message => {
      return {
        type: constants.ACTION
      }
    })

    reducer({
      [constants.ACTION]: (state) => {
        return {...state, message: ''}
      }
    })
  }
}

export default new SnackbarActionSet()