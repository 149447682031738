import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5E8EA3',
      main: '#005a79',
      dark: '#44546a',
    },
    secondary: {
      light: '#76CED9',
      main: '#6ca0b1',
      dark: '#496e7a',
    },
    error: {
      main: '#FA0057'
    },
    warning: {
      main: '#FA9C00',
    },
    success: {
      main: '#A3CE00'
    },
    type: 'light',
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'HarmoniaSans,Avenir,sans-serif',
  },
  overrides: {
    MuiPaper: {
      root: {
        '& .link-small': {
          margin: 5,
          textAlign: 'center',
          display: 'block',
          color: '#666565',
          fontSize: '0.8rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiCardContent: {
      root: {
        maxWidth: 1000,
        margin: '0 auto',
        width: '100%',
      }
    }
  }
})