import React, { Component } from 'react'
import {Loader} from 'components'
import withStyles from 'styles'

class HeaderLoader extends Component {
  render = () =>
    <Loader size={'0.875em'} className={this.props.classes.loader}/>
}

const styles = theme => ({
  loader: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    position: 'relative',
    bottom: 10,
    margin: 0,
    borderTop: `0.04em solid ${theme.palette.secondary.main}`,
    borderRight: `0.04em solid ${theme.palette.secondary.main}`,
    borderBottom: `0.04em solid ${theme.palette.secondary.main}`,
    borderLeft:"0.04em solid rgba(0, 0, 0, 0)",
  }
})

export default withStyles(styles)(HeaderLoader)
