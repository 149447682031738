import React, { Component, Fragment } from 'react'
import withStyles from 'styles'
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab'

export class FABFixed extends Component{

  static defaultProps = {
    color: 'primary'
  }

  render = () =>
    <Fragment>
      <Zoom
        in={true}
        timeout={300}
        style={{transitionDelay: 500}}
        unmountOnExit
      >
        <Fab color={this.props.color} className={this.props.classes.fab} onClick={this.props.onClick}>
          {this.props.children}
        </Fab>
      </Zoom>
      <div className={this.props.classes.buttonPadding}/>
    </Fragment>
}

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: 1
  },
  buttonPadding: {
    height: 50
  }
})

export default withStyles(styles)(FABFixed)