import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import withStyles from 'styles'

export class CenteredCard extends Component{

  static defaultProps = {
    centerVertically: true
  }

  render = () =>
    <section className={this.props.classes('wrapper', {centerVertically: this.props.centerVertically})}>
      <div className={this.props.classes.logo} />
      <Card className={this.props.classes.card}>
        {this.props.children}
      </Card>
    </section>

}

const styles = theme => ({
  wrapper: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    background: `linear-gradient(${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    position: 'relative',
    flex: 1
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    maxWidth: 300,
    '@media(max-width: 400px)': {
      maxWidth: 250
    }
  },
  centerVertically: {
    justifyContent: 'center',
    minHeight: '100vh',
    flexDirection: 'row',
    '& $card': {
      flex: '1 0 auto'
    }
  },
  logo: {
    backgroundImage: 'url(/harbour-logo.svg)',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    backgroundSize: 'contain',
    left: 20,
    top: 20,
    maxWidth: 100,
    maxHeight: 100,
    height: '100%',
    width: '100%',
    '& + div': {
      marginTop: 100
    },
    '&::after': {
      content: "'Business System Platform'",
      display: 'block',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '20px',
      margin: '15px 22px 0 120px',
      width: 0
    },
    '@media(max-width: 400px)': {
      maxWidth: 60,
      maxHeight: 60,
      '&::after': {
        margin: "10px 0 0 80px",
        fontSize: "16px",
      }
    },
    '@media(max-height: 400px)': {
       maxWidth: 60,
       maxHeight: 60
    },
  }
})

export default withStyles(styles)(CenteredCard)