import React, { Component } from 'react'
import { compose, deepSet, uuid } from 'utils'
import { ActionHeader, DatePicker } from 'components'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import withStyles from 'styles'
import FundInceptionInitialValueSetup from './FundInceptionInitialValueSetup'

export const MAXIMUM_FUND_INCEPTIONS = 5
export class FundInceptionSetup extends Component {

  state = {}

  componentDidMount = () => {
    this.ensureIdxOrder()
  }

  componentDidUpdate = (prevProps) => {
    this.ensureIdxOrder()
  }

  ensureIdxOrder = () => {
    if (this.fundInceptions.some((fi, idx) => fi.idx !== idx)) {
      this.handleValueChange(this.fundInceptions.sort((a, b) => (a.idx > b.idx) ? 1 : -1))
    }
  }

  get fundInceptions() {
    return this.props.value || []
  }

  handleAddFundInception = () => {
    if (this.fundInceptions.length < MAXIMUM_FUND_INCEPTIONS) {
      this.handleValueChange([
        ...this.fundInceptions,
        { inception: new Date(), uuid: uuid() }
      ])
    }
  }

  handleDeleteFundInception = rmIdx => () => {
    this.handleValueChange(this.fundInceptions.filter((_, i) => i !== rmIdx))
  }

  handleValueChange = value => {
    value = (value || []).map((fi, idx) => { return { ...fi, idx } })
    this.props.onChange && this.props.onChange({ target: { value } })
  }

  handleFundInceptionChange = (field, updateIdx) => ({ target: { value } }) => {
    this.handleValueChange(
      this.fundInceptions.map((fi, i) => updateIdx === i ?
        deepSet(value, field, this.fundInceptions[updateIdx]) : fi
      )
    )
  }

  renderFundInception = ({ id, uuid, fundInceptionInitialValues, inception }, index) =>
    <ListItem key={id || uuid} className={this.props.classes.fundInceptionListItem}>
      <DatePicker label='Inception Date' value={inception} onChange={this.handleFundInceptionChange('inception', index)} />
      <FundInceptionInitialValueSetup value={fundInceptionInitialValues} onChange={this.handleFundInceptionChange('fundInceptionInitialValues', index)} />
      <IconButton onClick={this.handleDeleteFundInception(index)} disabled={this.fundInceptions.length < 2}>
        <ClearIcon />
      </IconButton>
    </ListItem>

  render = () =>
    <Card className={this.props.classes.fundInceptionSetup}>
      <CardContent>
        <ActionHeader title="Inceptions">
          {this.fundInceptions.length < MAXIMUM_FUND_INCEPTIONS &&
            <IconButton onClick={this.handleAddFundInception}>
              <AddIcon />
            </IconButton>
          }
        </ActionHeader>
        <List>
          {this.fundInceptions.map(this.renderFundInception)}
        </List>
      </CardContent>
    </Card>
}

const styles = {
  fundInceptionSetup: {
    margin: '10px 0',
    flex: '1 1 400px'
  },
  fundInceptionListItem: {
    padding: 0,
    '& > div': {
      flex: '1 1 120px',
      margin: '0 15px'
    },
    '& :first-child': {
      marginLeft: 0
    },
    '& > :last-child': {
      marginRight: 0
    }
  }
}

export default compose(
  withStyles(styles)
)(FundInceptionSetup)