import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FundBenchmarkActions, IndexActions } from 'actionsets'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, Select, PercentageField, CommentsList } from 'components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, uuid } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import DatePicker from 'components/DatePicker'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'

export class Form extends InstanceFormMixin(Component) {

  constructor(props) {
    super(props)
    FundBenchmarkActions.bindActions(this)
    IndexActions.bindActions(this, 'indices')
  }

  dependsOn() {
    let benchmarkAction;
    if (this.editMode) {
      benchmarkAction = this.actions.show(this.objectId, { include: 'comments,comments.user' })
    } else {
      benchmarkAction = this.actions.set()
    }
    const indicesAction = this.actions.indices.index({
      page: 1, pageSize: 2000, fields: { indices: 'id,code,name' }, order: 'code',
    })
    const benchmarksAction = this.actions.index({
      page: 1, pageSize: 2000, fields: { fundBenchmarks: 'id,name,shortName' }, order: 'shortName',
    })
    return Promise.all([benchmarkAction, indicesAction, benchmarksAction])
  }

  handleDeleteComment = (commentId) => {
    return this.actions.update(
      {id: this.id, comments_attributes: [{id: commentId, _destroy: true}] },
      { include: 'comments,comments.user' }
    )
  }

  handleUpdateComment = (commentId, content) => {
    return this.actions.update(
      {id: this.id, comments_attributes: [{id: commentId, content}] },
      { include: 'comments,comments.user' }
    )
  }

  get id(){
    return this.props.match.params.id
  }

  get formObject() {
    const commentsAttributes = []
    if(this.newComment) {
      commentsAttributes.push({content: this.newComment})
    }

    return { ...this.props.fundBenchmark, ...this.state.formAttributes, 'comments_attributes': commentsAttributes }
  }

  get newComment() {
    return this.state.newComment
  }

  get fundBenchmark() {
    return this.props.fundBenchmark
  }

  newCommentChangedHandler = (event) => {
    this.setState({newComment: event.target.value})
  }

  newWeighting = () => {
    const simple = this.formObject.benchmarkType === "simple"
    const weightable_type = this.formObject.benchmarkType === "multi-asset" ? "FundBenchmark" : "Index"
    return { key: uuid(), weightable_type, weightable_id: "", percentage: simple ? 1 : "", hedging_rate: "", hurdle: "" }
  }

  updateWeightings = (groupKey, weightingKey, { add = false, remove = false, addGroup = false, effective, initial_value, ...attributes }) => {
    let weightingGroups = (this.formObject.weightingGroups || []).map(group => {
      const weightings = group.weightings.filter(w => (!remove || w.key !== weightingKey)).map(weighting => {
        const updated = (weighting.key === weightingKey) ? attributes : null
        return { ...weighting, ...updated }
      })
      const updatedGroup = {}
      if (group.key === groupKey) {
        if (add) {
          weightings.push(this.newWeighting())
        }
        updatedGroup.effective = effective || group.effective
        updatedGroup.initial_value = initial_value || group.initial_value
      }
      return { ...group, ...updatedGroup, weightings }
    })
    if (addGroup) {
      weightingGroups.unshift({ key: uuid(), effective: moment().format("YYYY-MM-DD"), weightings: [this.newWeighting()] })
    }
    weightingGroups = weightingGroups.filter(s => s.weightings.length > 0)
    this.handleFormObjectChange({ ...this.formObject, weightingGroups })
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Edit Benchmark - ${this.formObject.name || ''}`} />
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name' />
          <TextField fullWidth member='shortName' />
          <TextField fullWidth member='currency'/>
          <TextField fullWidth member='initialLevel' />
          <Select member='benchmarkType' fullWidth disabled={!!(this.formObject.weightingGroups || []).length}>
            <MenuItem value="simple">Simple</MenuItem>
            <MenuItem value="composite">Composite</MenuItem>
            <MenuItem value="multi-asset">Multi Asset</MenuItem>
          </Select>
          <TextField fullWidth member='finalIndexReturnEmailAddresses' />

          {!!this.formObject.benchmarkType && <div className={this.props.classes.weightingsDiv}>
            <ActionHeader title="Weightings">
              <IconButton onClick={e => this.updateWeightings(null, null, { addGroup: true })}>
                <AddIcon />
              </IconButton>
            </ActionHeader>
            {(this.formObject.weightingGroups || []).map(group => <Card className="weightingsCard" key={group.key}>
              <CardContent>
                <table className={this.props.classes.weightingTable}>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <DatePicker label="Effective" value={group.effective} fullWidth
                          onChange={({ target: { value } }) => this.updateWeightings(group.key, null, { effective: value })} />
                      </td>
                      <td colSpan="2">
                        <PercentageField value={group.initial_value || ''} label='Initial Return' fullWidth
                          onChange={({ target: { value } }) => this.updateWeightings(group.key, null, { initial_value: value })} />
                      </td>
                      <td>
                        <IconButton onClick={e => this.updateWeightings(group.key, null, { add: true })}>
                          <AddIcon />
                        </IconButton>
                      </td>
                    </tr>
                    {group.weightings.map(weighting => <tr key={weighting.key}>
                      <td className="weightable">
                        {weighting.weightable_type === "Index" &&
                          <Select value={weighting.weightable_id} label="Index" fullWidth
                            onChange={({ target: { value } }) => this.updateWeightings(group.key, weighting.key, { weightable_id: value })}>
                            {this.props.indices.map(index =>
                              <MenuItem key={index.id} value={index.id}><span className={this.props.classes.indexCode}>{index.code}</span>{index.name}</MenuItem>
                            )}
                          </Select>
                        }
                        {weighting.weightable_type === "FundBenchmark" &&
                          <Select value={weighting.weightable_id} label="Benchmark" fullWidth
                            onChange={({ target: { value } }) => this.updateWeightings(group.key, weighting.key, { weightable_id: value })}>
                            {this.props.fundBenchmarks.map(benchmark =>
                              <MenuItem key={benchmark.id} value={benchmark.id}>
                                <span className={this.props.classes.indexCode}>{benchmark.shortName}</span>{benchmark.name}
                              </MenuItem>
                            )}
                          </Select>
                        }
                      </td>
                      <td colSpan={this.formObject.benchmarkType === "multi-asset" ? 3 : 1}>
                        <PercentageField value={weighting.percentage || ""} label="Split" fullWidth disabled={this.formObject.benchmarkType === "simple"}
                          onChange={({ target: { value } }) => this.updateWeightings(group.key, weighting.key, { percentage: value })} />
                      </td>
                      {this.formObject.benchmarkType !== "multi-asset" && <>
                        <td>
                          <PercentageField value={weighting.hedging_rate || ""} label="Hedge" fullWidth
                            onChange={({ target: { value } }) => this.updateWeightings(group.key, weighting.key, { hedging_rate: value })} />
                        </td>
                        <td>
                          <PercentageField value={weighting.hurdle || ""} label="Hurdle" fullWidth
                            onChange={({ target: { value } }) => this.updateWeightings(group.key, weighting.key, { hurdle: value })} />
                        </td>
                      </>}
                      <td>
                        <IconButton onClick={e => this.updateWeightings(group.key, weighting.key, { remove: true })}>
                          <ClearIcon />
                        </IconButton>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </CardContent>
            </Card>)}
          </div>}
          <TextField fullWidth multiline={true} rows={4} onChange={this.newCommentChangedHandler} label='New comment' value={this.newComment} />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
      {
        this.editMode &&
        <CardContent>
          <ActionHeader title="Comments" />
          <CommentsList comments={this.fundBenchmark.comments} onDeleteComment={this.handleDeleteComment} onUpdateComment={this.handleUpdateComment} />
        </CardContent>
      }
    </PageContainer>
}

const styles = {
  weightingsDiv: {
    marginTop: 30,
    '& .weightingsCard': {
      marginTop: 10,
    },
  },
  indexCode: {
    margin: "0 5px",
    display: "inline-block",
    fontWeight: "bold",
    background: "#dbdbdb",
    padding: '0 5px 0 5px',
    textAlign: "center",
    borderRadius: "14px",
    float: "left",
    lineHeight: "1.6",
    paddingTop: "1px",
  },
  weightingTable: {
    '& td': {
      paddingRight: 10,
      whiteSpace: 'nowrap',
      minWidth: 80,
      maxWidth: 120
    },
    '& td:last-child': {
      paddingRight: 0,
      minWidth: 'auto',
    },
    '& td.weightable': {
      width: '99%',
    },
  }
}

export default compose(
  Dependent({ loader: true }),
  withStyles(styles),
  connect(({ fundBenchmarks }) => fundBenchmarks),
  connect(({ indices }) => indices),
)(Form)