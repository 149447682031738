import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class FundSummaryActionSet extends CrudActionSetBase('fundSummary', {
  apiResource: API['FundSummaries'],
  term: {
    plural: 'fundSummaries',
    singular: 'fundSummary'
  }
}){}
export default new FundSummaryActionSet()