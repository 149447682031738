import { ProtectedJsonAPI } from '.'

export const Notifications = ProtectedJsonAPI.extend(
  '/notifications',
  {
    index:   endpoint => endpoint,
    mine:    endpoint => endpoint.method('get').path('mine'),
    create:  endpoint => endpoint.method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    resend:  endpoint => endpoint.method('post').path(({id}) => `${id}/resend`),
    show:    endpoint => endpoint.path(({id}) => id)
  }
)
