import React from 'react'

const performTest = test =>{
  if(test === undefined){
    return true
  }

  if(!test){
    return false
  }

  if(typeof test === 'function'){
    return !!test()
  }

  return true
}

export const ConditionalRender = props => {
  const { requires, component: Component, ...rest } = props
  return performTest(requires) && <Component {...rest}/>
}
