import React, { Component }                from 'react'
import Select                              from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { uuid }                            from 'utils'
import MenuItem from '@material-ui/core/MenuItem';

export class LabeledSelect extends Component{

  constructor(props){
    super(props)
    this.htmlElmId = uuid()
  }

  get children(){
    if (Array.isArray(this.props.options)) {
      if (this.props.keyField && this.props.textField) {
        return this.props.options.map(option =>
          <MenuItem key={option[this.props.keyField]} value={option[this.props.keyField]}>{option[this.props.textField]}</MenuItem>
        )
      } else {
        return this.props.options.map((option, index) =>
          <MenuItem key={index} value={option}>{option}</MenuItem>
        )
      }
    } else if(this.props.options){
      return Object.entries(this.props.options).map(([value, label], index) =>
        <MenuItem key={index} value={value}>{label}</MenuItem>
      )
    }else{
      return this.props.children
    }
  }

  render = () => {
    const {fullWidth, label, error, children, options, helperText, keyField, textField, ...props } = this.props
    return (
      <FormControl fullWidth={!!fullWidth} error={!!error}>
        <InputLabel htmlFor={this.htmlElmId}>{label}</InputLabel>
        <Select
          inputProps={{id: this.htmlElmId}}
          {...props}
          value={props.value || ''}
        >
          {this.children}
        </Select>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default LabeledSelect