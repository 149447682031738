/**
 * A basic queue structure that keeps track of in-flight requests and their relative ordering
 */
export default class RequestQueue {
  constructor(name){
    this.requests                   = []
    this.sequence                   = 0
    this.processedSequence          = 0
    this.name                       = name
  }

  queue = () =>{
    const request = {
      type: this.name,
      sequence: this.sequenceNumber
    }
    this.requests.push(request)
    return request
  }

  complete = request => {
    const inOrder = request.sequence > this.processedSequence
    this.processedSequence = Math.max(this.processedSequence, request.sequence)
    this.requests.splice(this.requests.indexOf(request), 1)
    return inOrder
  }

  get sequenceNumber(){
    return this.sequence += 1
  }

  get length(){
    return this.requests.length
  }
}