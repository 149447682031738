import { ProtectedJsonAPI } from '.'

export const FundReturns = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({fundId}) => {
      return `/funds/${fundId}/fund_returns`
    }),
    create:  endpoint => endpoint.method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    show:    endpoint => endpoint.path(({id}) => id)
  }
)