import { ProtectedJsonAPI } from '.'

export const FileImports = ProtectedJsonAPI.extend(
  '/file_imports',
  {
    index:   endpoint => endpoint,
    create:  endpoint => endpoint.useFormData(true).method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    scrape:  endpoint => endpoint.method('post').path('/scrape'),
    show:    endpoint => endpoint.path(({id}) => id)
  }
)