import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FundActions, FundReturnActions, CustodianActions, MetricTypeActions, DerivedMetricTypeActions, SnackbarActions, FundBenchmarkActions, FundUserFavouriteActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { Pagination, LabeledSelect } from 'components'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { compose, formatValue } from 'utils'
import withStyles from 'styles'
import { connectQueryString } from 'containers/shared'
import Drawer from '@material-ui/core/Drawer'
import ColumnPicker from './ColumnPicker'
import FavouritesDialog from './FavouritesDialog'
import IconButton from '@material-ui/core/IconButton'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ArrowUp from '@material-ui/icons/KeyboardArrowUp'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { submitForm } from 'services/APIResource'
import DatePicker from 'components/DatePicker'
import { Loader } from 'components'
import moment from 'moment'
import { timeout, Authorization } from 'utils'
import TextField from '@material-ui/core/TextField'

export class Show extends Component {

  constructor(props) {
    super(props)
    FundActions.bindActions(this, 'fund')
    FundReturnActions.bindActions(this, 'fundReturn')
    CustodianActions.bindActions(this, 'custodian')
    MetricTypeActions.bindActions(this, 'metricType')
    DerivedMetricTypeActions.bindActions(this, 'derivedMetricType')
    FundBenchmarkActions.bindActions(this, 'fundBenchmark')
    SnackbarActions.bindActions(this, 'snackbar')
    FundUserFavouriteActions.bindActions(this, 'favourites')
  }

  state = {
    page: 1,
    columnsDrawerOpen: false,
    favouritesDialogOpen: false,
    queryString: undefined
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.filter.columns) {
      const { id, columnIds: columns } = (this.props.fundUserFavourites.find(f => (f.isDefault && f.granularity === this.granularity)) || {})
      if (columns) { this.handleFilterChange({ columns }) }
      this.setState({ favourite: (id || "") })
    } else {
      let { id } = (this.props.fundUserFavourites.find(f => f.columnIds === this.props.filter.columns) || {})
      this.setState({ favourite: (id || "") })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get fundOptions(){
    return { include: 'fundInceptions,fundBenchmarkFunds,fundBenchmarkFunds.fundBenchmark,fundColumns,fundColumns.custodian,fundColumns.metricType,fundColumns.derivedMetricType' }
  }

  dependsOn() {
    return Promise.all([
      this.actions.fund.show(this.id, this.fundOptions),
      this.actions.favourites.index({ filter: { fundId: this.id }, page: 1, pageSize: 2000, }),
      this.actions.custodian.index({
        page: 1, pageSize: 2000,
      }),
      this.actions.metricType.index({
        page: 1, pageSize: 2000,
      }),
      this.actions.derivedMetricType.index({
        page: 1, pageSize: 2000,
      }),
      this.loadFundReturns()
    ]);
  }

  loadFundReturns = async () => {
    try {
      // Start loading
      if (this._isMounted) { this.setState({ loadingFundReturns: true }) }

      const fundReturnsAction = this.actions.fundReturn.index({
        params: { fundId: this.id, returnType: this.granularity },
        filter: { ...(this.fromDate && { fromDate: this.fromDate }), ...(this.toDate && { toDate: this.toDate }) },
        pageSize: 15,
        page: this.props.page,
        order: this.sortDir === 'asc' ? 'date' : '-date'
      })

      // Wait for our both our API response to return and our minimum load time to elapse
      await Promise.all([fundReturnsAction, timeout(250)])

    } finally {
      // We're done
      if (this._isMounted) { this.setState({ loadingFundReturns: false }) }
    }
  }

  dependenciesMet() {
    return !!this.fund.id
  }

  get id() {
    return this.props.match.params.id
  }

  get fund() {
    return this.props.fund
  }

  get fromDate() {
    return this.props.filter.fromDate || ""
  }

  get toDate() {
    return this.props.filter.toDate || ""
  }

  get sortDir() {
    return this.props.filter.sortDir || 'desc'
  }

  get granularity() {
    return this.props.filter.granularity || 'monthly'
  }

  get visibleColumns() {
    const monthly = this.props.filter.granularity !== 'daily'
    return this.props.filter.columns ?
      this.props.filter.columns.split('-').map(columnID => this.fund.fundColumns.find(fc => `${fc.id}` === `${columnID}`)).filter(column => (monthly || !column.derivedMetricTypeId)) :
      this.fund.fundColumns.filter(column => column.available && column.isDefault && (monthly || !column.derivedMetricTypeId)).sort((a, b) => a.sequence > b.sequence ? 1 : -1)
  }

  handleFilterChange = (changes, callback) => {
    this.props.onFilterChange({ ...this.props.filter, ...changes }, callback)
  }

  handleToggleSortOrder = () => {
    this.handleFilterChange({ sortDir: this.sortDir === 'asc' ? 'desc' : 'asc' }, this.loadFundReturns)
  }

  handleEditColumns = () => {
    this.setState({ columnsDrawerOpen: true })
  }

  handleExportFile = () => {
    const data = {
      Authorization: global.localStorage.auth,
      data: {
        attributes: {
          returnType: this.granularity,
          column_ids: this.visibleColumns.map(({ id }) => id),
        }
      },
      order: this.sortDir === 'asc' ? 'date' : '-date',
      filter: { ...(this.fromDate && { fromDate: this.fromDate }), ...(this.toDate && { toDate: this.toDate }) },
    }
    submitForm(`/api/funds/${this.id}/fund_returns.xlsx`, data, 'post')
  }

  handleToggleGranularity = () => {
    const granularity = (this.granularity === 'monthly' ? 'daily' : 'monthly')
    if (this.state[granularity + 'Columns'] === undefined) {
      let { id: favourite, columnIds: columns } = (this.props.fundUserFavourites.find(f => (f.isDefault && f.granularity === granularity)) || {})
      this.setState({ [this.granularity + 'Columns']: this.props.filter.columns, favourite: (favourite || "") })
      this.handleFilterChange({ granularity, columns }, this.loadFundReturns)
    } else {
      const columns = this.state[granularity + 'Columns']
      let { id: favourite } = (this.props.fundUserFavourites.find(f => f.columnIds === columns) || {})
      this.setState({ [this.granularity + 'Columns']: this.props.filter.columns, favourite: (favourite || "") })
      this.handleFilterChange({ granularity, columns }, this.loadFundReturns)
    }
  }

  handleVisibleColumnChange = columns => {
    this.handleFilterChange({ columns: columns.map(({ id }) => id).join('-') })
    this.setState({ favourite: "" })
  }

  handlePageSelected = async page => {
    await this.props.onPageChange(page)
    this.loadFundReturns()
  }

  getNameForDerivedMetricType = dmt => {
    if (dmt.category === "fund_returns" && dmt.name.startsWith("since_inception_")) {
      const fi = this.fund.fundInceptions.find((fi) => fi.idx === (parseInt(dmt.name[dmt.name.length - 1]) - 1))
      const date = (fi && fi.inception)
      if (date) {
        return `${dmt.displayName.substring(0, dmt.displayName.length - 2)} (${moment(date).format('DD MMM YYYY')})`
      }
    }
    return dmt.displayName
  }

  renderRow = (fundReturn, visibleColumns, metricTypes, derivedMetricTypes) => <TableRow key={fundReturn.id}>
    <TableCell className={this.props.classes.headCol}>
      {fundReturn.date}
    </TableCell>
    {visibleColumns.map(col => {
      const value = fundReturn.metricDetails[col.id]
      const mt = metricTypes[col.metricTypeId]
      const dmt = derivedMetricTypes[col.derivedMetricTypeId]
      const format = ((dmt && dmt.format) || (mt && mt.format))
      const decimalPlaces = (dmt && dmt.format) ? null : (mt && mt.decimalPlaces);

      return <TableCell key={col.id}>
        {
          formatValue(format, value, decimalPlaces)
        }
      </TableCell>
    })}
  </TableRow>

  renderMetricTypeHeader = (col, metricTypes, use1MthLabel=false) => {
    const metricType = metricTypes[col.metricTypeId]
    if (use1MthLabel && metricType.annualise && this.granularity === 'monthly') { return "1 Mth" }
    return metricType.displayName
  }

  renderTopLevelHeader = (col, custodians, metricTypes, fundBenchmarkFund) => {
    const metricType = metricTypes[col.metricTypeId]
    if (fundBenchmarkFund && metricType.category === "benchmark_points") {
      return `${fundBenchmarkFund.fundBenchmark.name} (Inception ${moment(fundBenchmarkFund.inceptionDate).format('DD MMM YYYY')})`
    }
    return custodians[col.custodianId]
  }

  handleDateChange = (field) => ({ target: { value } }) => {
    this.handleFilterChange({ [field]: ((value && value.length > 9) ? value.substring(0, 10) : value) }, this.loadFundReturns)
  }

  handleFavouriteChange = ({ target: { value } }) => {
    this.setState({ favourite: value })
    const columns = (value === "defaults" ? undefined : this.props.fundUserFavourites.find(f => f.id === value).columnIds)
    this.handleFilterChange({ columns })
  }

  handleUpdateDescription = ({target: { value: description }}) => {
    if(description !== this.fund.description)
      this.actions.fund.update({id: this.id, description}, this.fundOptions)
  }

  render = () => {
    const visibleColumns = this.visibleColumns
    const custodians = this.props.custodians.reduce((agg, { id, name }) => ({ ...agg, [id]: name }), {})
    const metricTypes = this.props.metricTypes.reduce((agg, mt) => ({ ...agg, [mt.id]: mt }), {})
    const fundBenchmarkFunds = this.fund.fundBenchmarkFunds.reduce((agg, fbf) => ({ ...agg, [fbf.id]: fbf }), {})
    const derivedMetricTypes = this.props.derivedMetricTypes.reduce((agg, dmt) => (
      { ...agg, [dmt.id]: { ...dmt, displayName: this.getNameForDerivedMetricType(dmt) } }
    ), {})

    return <div className={this.props.classes.wrapper}>
      <PageContainer>
        <ActionHeader title={`${this.fund.name}`} variant='h6'>
          <Button color="primary" variant="contained" onClick={this.handleToggleGranularity} style={{ marginRight: 5 }}>
            {this.granularity === 'monthly' ? "Show Daily" : "Show Monthly"}
          </Button>
          <Button color="primary" variant="contained" onClick={this.handleEditColumns} style={{ marginRight: 5 }}>Edit Columns</Button>
          <Button color="secondary" style={{ color: 'white' }} variant="contained" onClick={this.handleExportFile}>Export&emsp;<DownloadIcon /></Button>
        </ActionHeader>
        {this.state.loadingFundReturns  ? <div style={{overflow:'visible', height: 0, width: '100%', paddingTop: 100, marginBottom: -100}}><Loader size={100} color='#FF4A00' /></div> : false }
        <Fragment>
          <div className={this.props.classes.filters}>
            <DatePicker clearable label="From Date" onChange={this.handleDateChange('fromDate')} value={this.fromDate} style={{ marginRight: 10 }} />
            <DatePicker clearable label="To Date" onChange={this.handleDateChange('toDate')} value={this.toDate} style={{ marginRight: 10 }} />
            <LabeledSelect label="Favourite" style={{ width: 200 }} value={this.state.favourite} onChange={this.handleFavouriteChange}>
              <MenuItem value="defaults">Defaults</MenuItem>
              {this.props.fundUserFavourites.filter(f => f.granularity === this.granularity).map(f => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)}
            </LabeledSelect>
            <div className={this.props.classes.description}>
              <h5>Description</h5>
              <TextField multiline onBlur={this.handleUpdateDescription} disabled={!Authorization.admin} defaultValue={this.fund.description} variant='outlined' style={{fontSize: 12, lineHeight: 1}}/>
            </div>
          </div>
          <Pagination totalPages={this.props.fundReturnsTotalPages || 1} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}>
          </Pagination>
          {
            this.state.columnsDrawerOpen && <Drawer
              anchor="right"
              classes={{ paper: this.props.classes.drawer }}
              open
              onClose={() => this.setState({ columnsDrawerOpen: false })}
            >
              <ColumnPicker
                availableColumns={this.granularity === 'monthly' ? this.fund.fundColumns : this.fund.fundColumns.filter(c => !c.derivedMetricTypeId)}
                onChange={this.handleVisibleColumnChange}
                onSaveAsFavourite={() => this.setState({ favouritesDialogOpen: true })}
                value={visibleColumns}
              />
            </Drawer>
          }
          <FavouritesDialog open={this.state.favouritesDialogOpen} onClose={() => this.setState({ favouritesDialogOpen: false })}
            granularity={this.granularity} fundId={this.id} columnIds={this.props.filter.columns} />
          <div className={this.props.classes.containerDiv}>
            <Table>
              <TableBody>
                <TableRow className="header topheader top-labels">
                  <TableCell className={this.props.classes.headCol}></TableCell>
                  {visibleColumns.map((col, i, cols) => {
                    const same = i > 0 && cols[i - 1].custodianId === col.custodianId && cols[i - 1].fundBenchmarkFundId === col.fundBenchmarkFundId
                    let colSpan = 1;
                    for (var x = i + 1; x < cols.length; x++) {
                      if (cols[x].custodianId === col.custodianId && cols[x].fundBenchmarkFundId === col.fundBenchmarkFundId) { colSpan++ }
                      else { break }
                    }
                    return <Fragment key={`${col.id}-${i}`}>
                      {!same && <TableCell colSpan={colSpan}>
                        <b>{this.renderTopLevelHeader(col, custodians, metricTypes, fundBenchmarkFunds[col.fundBenchmarkFundId])}</b>
                      </TableCell>}
                    </Fragment>
                  })}
                </TableRow>
                <TableRow className="header top-labels">
                  <TableCell className={this.props.classes.headCol}><b>{this.fund.telCode}</b></TableCell>
                  {visibleColumns.map((col, i, cols) => {
                    const same = i > 0 && cols[i - 1].custodianId === col.custodianId && cols[i - 1].metricTypeId === col.metricTypeId && cols[i - 1].fundBenchmarkFundId === col.fundBenchmarkFundId
                    let colSpan = 1;
                    for (var x = i + 1; x < cols.length; x++) {
                      if (cols[x].custodianId === col.custodianId && cols[x].metricTypeId === col.metricTypeId && cols[x].fundBenchmarkFundId === col.fundBenchmarkFundId) { colSpan++ }
                      else { break }
                    }
                    return <Fragment key={`${col.id}-${i}`}>
                      {!same && <TableCell colSpan={colSpan}>
                        {(colSpan > 1 || col.derivedMetricTypeId) && <b>{this.renderMetricTypeHeader(col, metricTypes)}</b>}
                      </TableCell>}
                    </Fragment>
                  })}
                </TableRow>
                <TableRow className="header">
                  <TableCell className={this.props.classes.headCol}>
                    <b>
                      Date
                      <IconButton size='small' style={{ padding: 5, top: 7, marginTop: -15 }} onClick={this.handleToggleSortOrder}>
                        {this.sortDir === 'asc' ? <ArrowUp size='small' /> : <ArrowDown size='small' />}
                      </IconButton>
                    </b>
                  </TableCell>
                  {visibleColumns.map((col, i) => {
                    return <TableCell key={`${col.id}-${i}`}>
                      <b>{(derivedMetricTypes[col.derivedMetricTypeId] && derivedMetricTypes[col.derivedMetricTypeId].displayName) || this.renderMetricTypeHeader(col, metricTypes, true)}</b>
                    </TableCell>
                  })}
                </TableRow>
                {this.props.fundReturns.map((r) => this.renderRow(r, visibleColumns, metricTypes, derivedMetricTypes))}
                {this.props.fundReturns.length === 0 && <TableRow>
                  <TableCell className={this.props.classes.headCol}></TableCell>
                  <TableCell colSpan={visibleColumns.length}>{`No ${this.granularity} returns data found` + ((this.fromDate || this.toDate) ? " for the selected date period" : "")}</TableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </div>
          <Pagination totalPages={this.props.fundReturnsTotalPages || 1} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        </Fragment>
      </PageContainer>
    </div>
  }
}

const styles = theme => ({
  headCol: {
    position: "absolute",
    width: 150,
    paddingTop: 12,
    left: "0",
    top: "auto",
    padding: 0,
    margin: 0,
    height: '40px',
    '@media(max-height: 1000px)': {
      height: '30px',
    },
    background: '#D3DCE0',
    '& > b': {
      paddingTop: 0
    }
  },
  containerDiv: {
    overflowX: 'scroll',
    marginLeft: 150,
    marginTop: 4,
    '& .top-labels': {
      height: 30,
      '& $headCol': {
        height: 30,
      }
    },
    '& .header': {
      background: theme.palette.secondary.light
    },
    '& .header $headCol': {
      background: '#005A7A',
      color: 'white',
    },
    '& .header.topheader': {
      background: '#005A7A',
    },
    '& .header.topheader td': {
      background: '#005A7A',
      color: 'white',
    },
    '& tr': {
      height: '40px',
      '@media(max-height: 1000px)': {
        height: '30px',
      },
      padding: 0,
      margin: 0,
      '&:hover': {
        '& $headCol': {
          background: theme.palette.primary.light,
          fontWeight: 'bold'
        },
        background: theme.palette.primary.light,
        '& td:hover': {
          background: theme.palette.primary.main,
          color: 'white',
          cursor: 'pointer'
        }
      }
    },
    '& tr.header td': {
      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
    },
    '& td': {
      whiteSpace: 'nowrap',
      paddingLeft: 10,
      paddingRight: 10
    },
  },
  description: {
    flex: 1,
    display: 'inline-block',
    bottom: 0,
    margin: '0 10px',
    '@media(min-width: 1400px)': {
      position: 'absolute',
    },
    '& h5': {
      margin: 0,
      fontWeight: "normal",
      color: "#757575",
      fontSize: "16px",
      marginTop: "18px",
    },
    '& > div > div': {
      padding: 5
    },
    '& textarea': {
      fontSize: 12,
      lineHeight: 1,
      maxHeight: 100,
      width: 220,
      overflowY: 'scroll',
    },
  },
  filters: {
    position: 'relative'
  },
  wrapper: {
    maxWidth: '100vw',
    overflowX: 'hidden',
    '& > div': {
      width: '85%',
      maxWidth: 4000,
    }
  },
  drawer: {
    maxWidth: 300
  }
})

export default compose(
  Dependent({ loader: true, clearOnLoad: true }),
  withStyles(styles),
  connectQueryString('fundReturns'),
  connect(({ fundReturns }) => fundReturns),
  connect(({ funds }) => funds),
  connect(({ fundUserFavourites }) => fundUserFavourites),
  connect(({ custodians }) => custodians),
  connect(({ metricTypes }) => metricTypes),
  connect(({ derivedMetricTypes }) => derivedMetricTypes),
)(Show)