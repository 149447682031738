import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NotificationActions, SnackbarActions, UserActions } from 'actionsets'
import { Pagination, ErrorBanner, SearchCombo } from 'components'
import Dependent from 'containers/shared/Dependent'
import NotificationIcon from '@material-ui/icons/Message'
import ResendIcon from '@material-ui/icons/Refresh'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import LoadedCard from 'components/LoadedCard'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { userFriendlyDate } from 'utils'
import withStyles from 'styles'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DatePicker from 'components/DatePicker'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import HelpButton from 'components/HelpButton'

export class List extends Component{

  constructor(props){
    super(props)
    NotificationActions.bindActions(this)
    UserActions.bindActions(this, 'users')
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return Promise.all([
      this.actions.users.index(),
      this.loadNotifications()
    ])
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.query !== this.props.query) {
      this.loadNotifications()
    }
  }

  loadNotifications = () => {
    return this.actions.index({
      page: this.props.page,
      filter: this.filter,
      include: 'user'
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  linkToAction = notification => () => {
    if(notification.linkTo)
      this.props.history.push('/' + notification.linkTo)
  }

  editNotification = id => event => {
    this.props.history.push(`/notifications/${id}/edit`)
    event.stopPropagation()
  }

  deleteNotification = id => event => {
    this.actions.destroy({id})
                .then(this.loadNotifications)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  handleResendNotification = (id, delivery) => event => {
    this.actions.resend({id}, {delivery})
                .then(this.loadNotifications)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    this.setState({anchorEl: undefined})
    event.stopPropagation()
  }

  handleShowResendMenu = resendId => event => {
    this.setState({anchorEl: event.currentTarget, resendId})
    event.stopPropagation()
    event.preventDefault()
  }

  get notifications(){
    return this.props.notifications
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadNotifications()
  }

  get filter(){
    return this.props.filter
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderNotificationListItem = (notification) => {
    const {id, title, message, createdAt, read, typeDisplayName, user: { email }} = notification
    return (
      <ListItem button onClick={this.linkToAction(notification)} key={id} className={this.props.classes({read})}>
        <div className={this.props.classes.listItemIcon}>
          <ListItemIcon >
            <NotificationIcon  className={this.props.classes.notificationIcon}/>
          </ListItemIcon>
          <Tooltip title='Resend notification'>
            <ListItemIcon onClick={this.handleShowResendMenu(id)}>
              <ResendIcon className={this.props.classes.resendIcon}/>
            </ListItemIcon>
          </Tooltip>
        </div>
        <ListItemText primary={
            <div className={this.props.classes.notifcationTextPrimary}>
              <span className={this.props.classes.notificationContent}>
                To {email}: {title} ({userFriendlyDate(createdAt)})
              </span>
              <span className={this.props.classes.notificationTypeContent}>
                {typeDisplayName}
              </span>
            </div>
          }
          secondary={message}
        />
      </ListItem>
    )
  }


  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () => {
    const searchCombo = (
      <SearchCombo onFilterChange={this.props.onFilterChange} filter={this.filter}>
        <Select member='user_id' label='User' fullWidth>
          <MenuItem value={''}><em>All</em></MenuItem>
          {this.props.users.map(user =>
            <MenuItem key={user.id} value={user.id}>{user.email}</MenuItem>
          )}
        </Select>
        <div>
          <DatePicker label='Start Date' member='created_at.start'/>
          <DatePicker label='End Date' member='created_at.end'/>
        </div>
        <Select member='notification_type' label='Type' fullWidth>
          <MenuItem value={''}><em>All</em></MenuItem>
          <MenuItem value={'ImportFailed'}>Import Failed</MenuItem>
          <MenuItem value={'System'}>System</MenuItem>
        </Select>
      </SearchCombo>
    )

    return (
      <PageContainer>
      <ActionHeader title="Notifications">
        <HelpButton>
          In-app notifications are reactive and display the current value for a
          record, rather than the value at the time the notification was created
        </HelpButton>
        <Button color="primary" variant="contained" onClick={() => this.props.history.push('/notifications/new')}>Add</Button>
      </ActionHeader>
        {this.renderErrorMessages()}
          <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo}/>
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleCloseResendMenu}
          >
            <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['app'])}>In App</MenuItem>
            <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['email'])}>Email</MenuItem>
            <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['app', 'email'])}>Both</MenuItem>
          </Menu>
          <LoadedCard>
            <MuiList dense>
              {this.notifications.map(this.renderNotificationListItem)}
            </MuiList>
          </LoadedCard>
          <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      </PageContainer>
    )
  }
}

const styles = {
  read: {
    opacity: 0.6
  },
  notifcationTextPrimary: {
    display: 'flex',
    flexDirection: 'row'
  },
  notificationContent: {
    flex: 1
  },
  notificationTypeContent: {
    color: "#283888",
    fontSize: "0.9em",
    textAlign: "right",
    marginLeft: 50,
    marginRight: -15
  },
  resendIcon:{
    position: 'absolute',
    left: 15,
    top: 15,
    opacity: 0,
    transition: 'opacity 200ms',
  },
  notificationIcon:{
    position: 'absolute',
    left: 15,
    top: 15,
    opacity: 1,
    transition: 'opacity 200ms',
  },
  listItemIcon: {
    display: "inline-block",
    width: 26,
    height: 26,
    marginRight: -10,
    '&:hover': {
      '& $resendIcon': {
        opacity: 1
      },
      '& $notificationIcon': {
        opacity: 0
      }
    }
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connectQueryString('notifications'),
  connect(({notifications, users: { users }}) => ({...notifications, users}))
)(List)
