import React, {Component} from 'react'
import withStyles from 'styles'

export class LinkButton extends Component {
  render = () => {
    const {classes, disabled = false, onClick, ...props} = this.props
    return (
      <button {...props} {...(!disabled && { onClick })} className={classes({root: true, disabled})}/>
    )
  }
}

const styles = theme => ({
  root: {
    border: 'none',
    background: 'transparent',
    color: theme.palette.primary.main,
    display: 'inline',
    appearance: 'none',
    textAlign: 'left',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:focus': {
      border: 'none',
      background: 'transparent',
      outline: 'none',
    }
  },
  disabled: {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'auto',
  }
})

export default withStyles(styles)(LinkButton)