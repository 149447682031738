import React, { Component } from 'react'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LabeledSelect, LabeledCheckbox } from 'components'
import MenuItem from '@material-ui/core/MenuItem'
import { FundUserFavouriteActions } from 'actionsets'
import { connect } from 'react-redux'
import { compose } from 'utils'

export class FavouritesDialog extends Component {

  state = { favourite: "", mode: "new", name: "", isDefault: false }

  constructor(props) {
    super(props)
    FundUserFavouriteActions.bindActions(this)
  }

  handleChange = (stateItem) => ({ target: { value } }) => {
    this.setState({ [stateItem]: value, error: false })
  }

  handleFavouriteChange = ({ target: { value } }) => {
    const { isDefault } = value
    this.setState({ favourite: value, error: false, isDefault: !!isDefault })
  }

  handleCheck = (stateItem) => ({ target: { checked } }) => {
    this.setState({ [stateItem]: checked })
  }

  handleDelete = async () => {
    if (!this.state.favourite) {
      this.setState({ error: true })
      return
    }
    await this.actions.destroy({ id: this.state.favourite.id })
    await this.actions.index({ filter: { fundId: this.props.fundId }, page: 1, pageSize: 2000, })
    this.setState({ favourite: "", isDefault: false, error: false })
  }

  handleSave = async () => {
    const existing = (this.state.mode === "existing")
    if ((existing && !this.state.favourite) || (!existing && !this.state.name)) {
      this.setState({ error: true })
      return
    }
    let favourite = existing ? this.state.favourite : { name: this.state.name }
    favourite = {
      ...favourite, is_default: this.state.isDefault, columnIds: this.props.columnIds, granularity: this.props.granularity, fundId: this.props.fundId
    }
    await (existing ? this.actions.update(favourite) : this.actions.create(favourite))
    await this.actions.index({ filter: { fundId: this.props.fundId }, page: 1, pageSize: 2000, })
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ favourite: "", name: "", mode: "new", error: false, isDefault: false })
    if (this.props.onClose) { this.props.onClose() }
  }

  render = () => {
    return <Dialog open={this.props.open}>
      <DialogTitle>Manage Favourites</DialogTitle>
      <DialogContent>
        <div>
          <RadioGroup aria-label="mode" name="mode" value={this.state.mode} onChange={this.handleChange('mode')} row>
            <FormControlLabel value="new" control={<Radio />} label="New" />
            <FormControlLabel value="existing" control={<Radio />} label="Existing" />
          </RadioGroup>
        </div>
        <div>
          {this.state.mode === "new" && <TextField label='New Favourite' fullWidth value={this.state.name} onChange={this.handleChange('name')} error={this.state.error} />}
          {this.state.mode === "existing" && <LabeledSelect label="Existing Favourite" style={{ width: 200 }} value={this.state.favourite} onChange={this.handleFavouriteChange} error={this.state.error}>
            {this.props.fundUserFavourites.filter(f => f.granularity === this.props.granularity).map(f => <MenuItem key={f.id} value={f}>{f.name}</MenuItem>)}
          </LabeledSelect>}
        </div>
        <div>
          <LabeledCheckbox label="Make This My Default View" onChange={this.handleCheck("isDefault")} checked={this.state.isDefault} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={this.handleSave} color='primary'>Save</Button>
        {this.state.mode === "existing" && <Button variant='contained' onClick={this.handleDelete} color='primary'>Delete</Button>}
        <Button variant='contained' onClick={this.handleClose} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>
  }
}

const styles = theme => ({

})

export default compose(
  withStyles(styles),
  connect(({ fundUserFavourites }) => fundUserFavourites),
)(FavouritesDialog)