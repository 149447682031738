import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select } from 'components'
import TextField from '@material-ui/core/TextField'
import { MenuItem, Checkbox, ListItemText } from '@material-ui/core'
import { compose, deepSet } from 'utils'
import withStyles from 'styles'

export class FundCustodianSelector extends Component {

  handleValueChange = value => {
    this.props.onChange && this.props.onChange({ target: { value } })
  }

  handleCustodiansChanged = fundCustodians => {
    this.props.onCustodiansChanged && this.props.onCustodiansChanged(fundCustodians.map(fc => fc.custodian))
  }

  mapCustodians = (custodian) => {
    return { custodian, portfolioCode: "", ...this.fundCustodians.find(fc => `${fc.custodian.id}` === `${custodian.id}`) }
  }

  handleSelectedCustodianChange = ({ target: { value } }, name = null) => {
    const fundCustodians = this.props.custodians.filter(c => value.includes(c.id)).map(this.mapCustodians)
    this.handleValueChange(fundCustodians)
    this.handleCustodiansChanged(fundCustodians)
  };

  handlePortfolioCodeChange = (updateIdx) => ({ target: { value } }) => {
    this.handleValueChange(
      this.fundCustodians.map((fc, i) => updateIdx === i ?
        deepSet(value, "portfolioCode", this.fundCustodians[updateIdx]) : fc
      )
    )
  }

  mapCustodianMenuItems = ({ id, name }) =>
    <MenuItem key={id} value={id}>
      <Checkbox checked={this.fundCustodians.some(fc => `${fc.custodian.id}` === `${id}`)} />
      <ListItemText primary={name} />
    </MenuItem>

  get fundCustodians() {
    return this.props.value || []
  }

  render = () => <>
    <Select
      multiple
      label="Custodians"
      value={this.fundCustodians.map(fc => fc.custodian.id)}
      onChange={this.handleSelectedCustodianChange}
      renderValue={() => this.fundCustodians.map(fc => fc.custodian.name).join(', ')}
    >
      {this.props.custodians.map(this.mapCustodianMenuItems)}
    </Select>
    {this.fundCustodians.sort((a, b) => (a.custodian.id > b.custodian.id) ? 1 : -1).map(({ custodian }, index) =>
      <TextField key={custodian.id} fullWidth label={`${custodian.name} Portfolio Code`}
        onChange={this.handlePortfolioCodeChange(index)}
        value={this.fundCustodians.find(fc => `${fc.custodian.id}` === `${custodian.id}`).portfolioCode} />
    )}
  </>
}

const styles = {
}

export default compose(
  withStyles(styles),
  connect(({ custodians }) => custodians),
)(FundCustodianSelector)