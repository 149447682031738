import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NotificationActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import NotificationIcon from '@material-ui/icons/Message'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import LoadedCard from 'components/LoadedCard'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { userFriendlyDate } from 'utils'
import withStyles from 'styles'

export class Mine extends Component{

  constructor(props){
    super(props)
    NotificationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadNotifications()
  }

  loadNotifications = () => {
    return this.actions.mine({
      page: this.props.page,
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  linkToAction = notification => () => {
    this.actions.update({...notification, read: true})
    if(notification.linkTo)
      this.props.history.push('/' + notification.linkTo)
  }

  editNotification = id => event => {
    this.props.history.push(`/notifications/${id}/edit`)
    event.stopPropagation()
  }

  deleteNotification = id => event => {
    this.actions.destroy({id})
                .then(this.loadNotifications)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get notifications(){
    return this.props.mine
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadNotifications()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderNotificationListItem = (notification) => {
    const {id, title, message, createdAt, read, typeDisplayName} = notification
    return (
      <ListItem button onClick={this.linkToAction(notification)} key={id} className={this.props.classes({read})}>
        <ListItemIcon>
          <NotificationIcon />
        </ListItemIcon>
        <ListItemText primary={
            <div className={this.props.classes.notifcationTextPrimary}>
              <span className={this.props.classes.notificationContent}>
                {title} ({userFriendlyDate(createdAt)})
              </span>
              <span className={this.props.classes.notificationTypeContent}>
                {typeDisplayName}
              </span>
            </div>
          }
          secondary={message}
        />
      </ListItem>
    )
  }


  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <ActionHeader title="My Notifications" />
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPagesMine} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      <LoadedCard>
        <MuiList dense>
          {this.notifications.map(this.renderNotificationListItem)}
        </MuiList>
      </LoadedCard>
      <Pagination totalPages={this.props.totalPagesMine} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = {
  read: {
    opacity: 0.6
  },
  notifcationTextPrimary: {
    display: 'flex',
    flexDirection: 'row'
  },
  notificationContent: {
    flex: 1
  },
  notificationTypeContent: {
    color: "#283888",
    fontSize: "0.9em",
    textAlign: "right",
    marginLeft: 50,
    marginRight: -15
  }
}


export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connectQueryString('notifications'),
  connect(({notifications}) => notifications)
)(Mine)
