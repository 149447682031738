import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '.'

export class LoaderOverlay extends Component {

  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
  }

  static defaultProps = {
    size: 100,
    color: '#FF4A00'
  }

  render = () =>
    <div style={{
      height: '100%',
      width: '100%',
      minHeight: 120,
      position: 'absolute',
      left: 0,
      zIndex: 3
    }}>
      <div >
        <Loader size={this.props.size} color={this.props.color}/>
      </div>
    </div>
}

export default LoaderOverlay
