import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

export class Routes extends Component{

  render = () =>
    <Switch>
    </Switch>

}

export default withRouter(Routes)