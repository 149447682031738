import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TokenActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import { FormContext, CenteredCard, ErrorBanner } from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { MicrosoftSignInButton } from 'containers/auth'
import withStyles from 'styles'
import { compose } from 'utils'

export class SignIn extends Component{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  signIn = async (params) => {
    try{
      await this.actions.create(params)
      const location = (this.props.savedLocation && !this.props.savedLocation.match(/^\/?tokens\//)) ?
        this.props.savedLocation :
        '/'
      this.props.history.push(location)
      this.actions.snackbar.show('Signed in succesfully')
    }catch(err){
      this.actions.snackbar.show('Signed in failed')
    }
  }

  signInWithPassword = async () => {
    this.signIn({...this.state, type: 'default'})
  }

  signInWithMicrosoft = (credentials) => {
    this.signIn({...credentials, type: 'microsoft'})
  }

  errorFor = key => {
    try{
      return this.props.errors.create.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <FormContext
        context={this.state} errorContext={{email: this.errorFor('email'), password: this.errorFor('password')}} onChange={state => this.setState(state)} onSubmit={this.signInWithPassword}>
        <CardContent>
          <Typography variant='h6'>Sign in</Typography>
          {this.props.errors.create && <ErrorBanner>{this.props.errors.create.title}</ErrorBanner>}
          <div className={this.props.classes.signInWithMicrosoft}>
            <MicrosoftSignInButton className={this.props.classes.actionBtn} label='' onAuthorized={this.signInWithMicrosoft}/>
            <hr/>
            <span>OR</span>
          </div>
          <FormGroup>
            <TextField member='email'   type='text'/>
            <TextField member='password'type='password'/>
              <FormControlLabel
                control={
                  <FormContext context={this.state} onChange={state => this.setState(state)}>
                  <Checkbox member="rememberMe" value="checkedA"/>
                  </FormContext>
                }
                label="Remember Me"
              />
          </FormGroup>
        </CardContent>
        <CardActions className={this.props.classes.actions}>
          <Button fullWidth variant='contained' color='primary' type='submit'>Submit</Button>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot password</Link>
      </FormContext>
    </CenteredCard>

}

const styles = (theme) =>({
  signInWithMicrosoft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 15,
    marginBottom: "-20px",
    textAlign: 'center',
    '& > img': {
      cursor: 'pointer',
      margin: '0 auto',
      '&:hover': {
        opacity: 0.7
      }
    },
    '& hr': {
      flex: "1",
      border: "1px dashed #d2d2d2",
      width: "100%",
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'column'
  },
  actionBtn: {
    width: '100%',
    marginTop: '10px'
  }
})

export default compose(
  withStyles(styles),
  connect(({tokens}) => tokens)
)(SignIn)