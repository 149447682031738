import React, { Component } from 'react'
import { LoaderOverlay } from 'components'

/**
 * The default loader renders inline
 * @param  {[type]} props [description]
 * @return {[type]}       [description]
 */
export const DefaultLoader = props =>
  <LoaderOverlay/>

/**
 * The Dependent high-order component allows you to make rendering of a child component conditional on a
 * certain dependency being met, and allows you to trigger actions if the attempt to load the dependency fails.
 * This way your child component does not need to contain dependency null checking code.
 * It will only render if all dependencies have been met.
 *
 * If the component is dependent on certain asynchronous actions being triggered when it first mounts
 * these will no longer be triggered because mounting of the component will be prevented by the
 * Dependent wrapper. To circumvent this, you can expose a `dependsOn` function on your component
 * which will be invoked when the component first attempts to mount.
 *
 * The `dependsOn` function can return a promise if you want to handle the case where
 * meeting a dependency has failed
 *
 * The component 'must' define a `dependenciesMet` function, which will return true if all properties required
 * to render the component are present
 *
 * The component can optionally define a `dependenciesFailed` handler. Otherwise
 * the default response is to redirec the user to the '/not_found' url
 *
 * The wrapper can optionally show a loader component while dependencies are being loaded
 * To do this wrap the component as follows:
 *
 * export Dependent({loader: true})(NeedsUser)
 *
 * OR
 *
 * export Dependent({loader: <MyCustomLoader/>})(NeedsUser)
 *
 * The loader will only display after 200ms and if it does display, will do so for a minimum of 250ms
 * (to avoid flashing rapidly on the screen)
 *
 * E.g
 *
 * class NeedsUser extends Component{

 *   dependsOn(){
 *     return this.actions.load(this.props.match.params.id))
 *   }
 *
 *   dependenciesMet(){
 *     return !!this.user
 *   }
 *
 *   render(){
 *     .. Render here safe in the assumption that this.props.user is populated
 *   }
 * }
 *
 * export Dependent(NeedsUser)
 */

export class Dependent extends Component{

  static SHOW_LOADER_AFTER    = 600;
  static SHOW_LOADER_AT_LEAST = 250;

  constructor(props){
    super(props)
    const { component: Component, ...rest} = this.props
    this.scope = new Component(rest)
    this.state = { showLoader: false, dependsOnResolved: false }
  }

  static defaultProps = {
    options: {},
    wrapperStyle: {},
    wrapperClassName: ''
  }

  componentDidMount = () => {
    this._isMounted = true
    this.requestEnableLoader()
    return Promise.resolve(this.dependsOn()).then(() => {
      this.setState({dependsOnResolved: true})
    }).catch(this.dependenciesFailed.bind(this.scope))
  }

  requestEnableLoader = () => {
    clearTimeout(this.enableLoaderTimeout)
    this.enableLoaderTimeout = setTimeout(this.enableLoader, Dependent.SHOW_LOADER_AFTER)
  }

  requestDisableLoader = () => {
    clearTimeout(this.disableLoaderTimeout)
    this.disableLoaderTimeout = setTimeout(this.disableLoader, Dependent.SHOW_LOADER_AT_LEAST)
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  enableLoader = () => {
    if(this.props.options.loader && ! this.dependenciesMet() && this._isMounted){
      this.setState({showLoader: true})
      this.requestDisableLoader()
    }
  }

  disableLoader = () => {
    if(this.dependenciesMet()){
      if(this._isMounted){
        this.setState({showLoader: false})
      }
    }else{
      this.requestDisableLoader()
    }
  }

  componentWillReceiveProps({ component: _, ...props}){
    this.scope.componentWillReceiveProps && this.scope.componentWillReceiveProps(props)
    this.scope.props = props
    this.requestEnableLoader()
  }

  get loader(){
    if(this.props.options.loader){
      return this.props.options.loader === true ?
        <DefaultLoader/> :
        <this.props.options.loader/>
    }
    return false
  }

  dependenciesMet = () => {
    const { dependenciesMet } = this.scope
    if(!dependenciesMet){
      throw new Error('Dependent component must define a `dependenciesMet` function')
    }
    try{
      return dependenciesMet.call(this.scope) && this.state.dependsOnResolved
    }catch(err){
      console.log(err)
      return false
    }
  }

  redirect = (path) => {
    if (this.props.history)
      this.props.history.replace(path)
  }

  get dependsOn(){
    return (this.scope.dependsOn || (() => {
      return true
    })).bind(this.scope)
  }

  get dependenciesFailed(){
    return this.scope.dependenciesFailed || (error => {
      error = error.length ? error[0] : error
      switch(error.status){
      case 403: break
      case 408: {
        this.redirect('/timed_out')
        break
      }
      case 504: {
        this.redirect('/timed_out')
        break
      }
      case 404: {
        this.redirect('/not_found')
        break
      }
      default: {
        console.error('Unexpected error', error)
        this.redirect('/not_found')
        break
      }}
    })
  }

  render = () => {
    const { component: Component, ...rest} = this.props
    const isLoaded = this.dependenciesMet() && !this.state.showLoader
    if(isLoaded && !this.props.options.clearOnLoad){
      this.lastValid = <Component {...rest}/>
    }
    return (
      <div className={this.props.options.wrapperClassName} style={this.props.options.wrapperStyle}>
        {this.state.showLoader && this.loader}
        {
          this.props.options.clearOnLoad ?
            (isLoaded && <Component {...rest}/>) :
            this.lastValid
        }
      </div>
    )
  }
}

export default (optionsOrComponent) => {
  switch(typeof optionsOrComponent){
  case 'function': {
    return props => <Dependent {...props} component={optionsOrComponent} />
  }
  default: {
    return component => props => <Dependent {...props} options={optionsOrComponent} component={component} />
  }}
}
