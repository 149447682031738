export default class ResponseCache{


  static LOCAL_STORE_KEY = 'APIResource.ResponseCache'

  static clear = () => {
    window.localStorage.removeItem(ResponseCache.LOCAL_STORE_KEY)
  }

  constructor(cacheId){
    if(!ResponseCache.store){
      try{
        ResponseCache.store = JSON.parse(window.localStorage.getItem(ResponseCache.LOCAL_STORE_KEY)) || {}
      }
      catch(err){
        ResponseCache.store = {}
      }
    }
    this.memoryStore = {}
    ResponseCache.store[cacheId] = this.localStore = ResponseCache.store[cacheId] || {}
  }

  save(){
    try{
      window.localStorage.setItem(ResponseCache.LOCAL_STORE_KEY, JSON.stringify(ResponseCache.store))
    }catch(err){
      console.warn(err)
    }
  }

  stringifyKey = key => {
    return JSON.stringify(Object.entries(key).sort(([ka, _a],[kb, _b]) => {
      if(ka < kb) return -1
      if(ka > kb) return 1
      return 0
    }))
  }

  set = (ns, key, value, { localStorage } = { localStorage: false }) => {
    if(localStorage){
      return this.setLocal(ns, key, value)
    }else{
      return this.setMemory(ns, key, value)
    }
  }

  get = (ns, key, { localStorage } = { localStorage: false }) => {
    if(localStorage){
      return this.getLocal(ns, key)
    }else{
      return this.getMemory(ns, key)
    }
  }

  getLocal = (ns, key) => {
    this.localStore[ns]  = this.localStore[ns] || {}
    return this.localStore[ns][this.stringifyKey(key)]
  }

  setLocal = (ns, key, value) => {
    this.localStore[ns]  = this.localStore[ns] || {}
    this.localStore[ns][this.stringifyKey(key)] = { createdAt: +new Date(), value }
    this.save()
  }

  getMemory = (ns, key) => {
    this.memoryStore[ns]  = this.memoryStore[ns] || {}
    return this.memoryStore[this.stringifyKey(key)]
  }

  setMemory = (ns, key, value) => {
    this.memoryStore[ns]  = this.memoryStore[ns] || {}
    this.memoryStore[this.stringifyKey(key)] = { createdAt: +new Date(), value }
  }
}