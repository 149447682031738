import thunk from 'redux-thunk'
import reduxPromiseMiddleware from 'middleware/reduxPromiseMiddleware'
import authenticationMiddleware from 'middleware/authenticationMiddleware'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducers } from 'actionsets'

const middlewares = [
  reduxPromiseMiddleware,
  authenticationMiddleware,
  thunk
]

const enhancedCreateStore = applyMiddleware(...middlewares)(createStore)
const store               = enhancedCreateStore(
  combineReducers(reducers),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
