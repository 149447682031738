import React, { Component } from 'react'

export class LogoInner extends Component{

  static defaultProps = {
    innerFill: '#FFFFFF',
    fill: '#114769',
    bounds: [0,0,160,160],
    width: 106.5,
    height: 106.5,
  }

  render = () =>
    <svg version="1.1" viewBox="0 0 160 160" width={this.props.width} height={this.props.height}>
      <g id="Layer_1_1_">
        <rect x="26.25" y="25.5" fill={this.props.innerFill} width="106.25" height="106.5"/>
        <path fill={this.props.fill} d="M79.473,0.544C35.909,0.62,0.665,35.991,0.74,79.551c0.076,43.549,35.454,78.801,79.019,78.721
          c43.56-0.078,78.812-35.449,78.736-79.001C158.415,35.712,123.039,0.469,79.473,0.544z M83.295,120.889
          c0,0-24.831-10.668-48.932-1.764c0,0,24.066-31.314,24.066-92.967c0,0,5.861,27.107,19.384,80.51c0,0,0.777,3.598,3.444,4.617
          c2.15,0.83,4.21,0.29,5.785-1.689c3.168-3.957,12.177-18.141,16.563-61.416c0,0,11.783,44.703,20.102,73.166
          C123.708,121.346,106.754,129.928,83.295,120.889z"/>
      </g>
    </svg>

}

export default LogoInner


