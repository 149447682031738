import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import { SnackbarActions, GlobalLoaderActions } from 'actionsets'
import { SnackbarActions } from 'actionsets'
import MicrosoftXS from 'imgs/ms_signin_light.png'
import { MicrosoftService } from '../../services'
import withStyles from 'styles'
import { compose } from 'utils'


export class MicrosoftSignInButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onAuthorized: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')
    // GlobalLoaderActions.bindActions(this, 'loader')
  }

  authorizationCallback = async authHash => {
    let {error, errorDescription, authCode, redirectUri} = authHash

    if(error) {
      this.actions.snackbar.show(`Failed to retrieve Microsoft authorization code. ${errorDescription}`)
    } else {
      this.actions.snackbar.show('Microsoft authorization code retrieved.')
      // this.actions.loader.startLoad()
      try {
        await this.props.onAuthorized({ authCode, redirectUri })
      }finally{
        // this.actions.loader.stopLoad()
      }
    }
  }

  authorize = () => {
    MicrosoftService.authorize(this.authorizationCallback)
  }

  render = () => {
    return(
      <img  onClick={this.authorize} alt={this.props.label} src={MicrosoftXS}/>
    )
  }
}

const styles = (theme) =>({
  loginButton: {
  },
})

export default compose(
  withStyles(styles),
  connect(() => ({}))
)(MicrosoftSignInButton)