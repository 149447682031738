import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class IndexActionSet extends CrudActionSetBase('index', {
  apiResource: API['Indices'],
  term: {
    plural: 'indices',
    singular: 'index'
  }
}){}
export default new IndexActionSet()