import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import FaceIcon from '@material-ui/icons/Face'
import CodeIcon from '@material-ui/icons/Code'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { ConfirmationDialog } from 'components'

export class Profile extends Component {

  constructor(props) {
    super(props)
    UserActions.bindActions(this)
    this.state = {}
  }

  dependenciesMet() {
    return !!this.props.currentUser.id
  }

  get currentUser() {
    return this.state.user || this.props.currentUser
  }

  showUserWithExcelToken = async () => {
    this.setState({ confirmExcelToken: false })
    const { data: user } = await this.actions.show(this.props.currentUser.id, { with_excel_token: true })
    this.setState({ user })
  }

  renderExcelTokenConfirmation = () =>
    <ConfirmationDialog title="Excel Access Token" confirmLabel="OK"
      open={!!this.state.confirmExcelToken}
      onConfirm={this.showUserWithExcelToken}
      onCancel={() => { this.setState({ confirmExcelToken: false }) }} maxWidth={'lg'}>
      <p>The excel token grants direct access to all fund data.</p>
      <p><b>Keep it secret.</b> Protect it the way you would a password.</p>
    </ConfirmationDialog>

  render = () =>
    <PageContainer>
      <ActionHeader title="Profile" />
      <List>
        <ListItem>
          <ListItemSecondaryAction>
            <IconButton onClick={() => this.props.history.push(`/users/${this.currentUser.id}/edit`)}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary={this.currentUser.name} secondary='name' />
        </ListItem>
        <ListItem>
          <ListItemIcon><EmailIcon /></ListItemIcon>
          <ListItemText primary={this.currentUser.email} secondary='email' />
        </ListItem>
        <ListItem>
          <ListItemIcon><FaceIcon /></ListItemIcon>
          <ListItemText primary={this.currentUser.role} secondary='role' />
        </ListItem>
        {this.currentUser.role === 'admin' &&
          <ListItem onClick={this.currentUser.excelToken ? undefined : () => this.setState({ confirmExcelToken: true })} style={this.currentUser.excelToken ? {} : { cursor: 'pointer' }}>
            <ListItemIcon><CodeIcon /></ListItemIcon>
            <ListItemText primary={this.currentUser.excelToken} secondary={this.currentUser.excelToken ? 'token' : 'click to reveal excel access token'} />
          </ListItem>
        }
      </List>
      {this.renderExcelTokenConfirmation()}
    </PageContainer>
}


export default connect(({ tokens }) => tokens)(Dependent({ loader: true, clearOnLoad: true })(Profile))