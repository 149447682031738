import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { Authorization } from 'utils'

export class Show extends Component{

  constructor(props){
    super(props)
    UserActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id)
  }

  dependenciesMet(){
    return !!this.user.id
  }

  get id(){
    return this.props.match.params.id
  }

  get user(){
    return this.props.user
  }


  render = () =>
    <PageContainer>
      <ActionHeader title={`User - ${this.user.name}`}/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              {
                Authorization.admin &&
                <Link to={`/users/${this.user.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </PageContainer>
}


export default connect(({users}) => users)(Dependent({loader: true, clearOnLoad: true})(Show))