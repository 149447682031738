import { formatLocaleDate } from '.'

export function formatValue(format, value, decimalPlaces = 2) {
  if (!decimalPlaces && decimalPlaces !== 0) { decimalPlaces = 2 }
  if (!value && value !== 0) { return '' }
  if (format === 'string') {
    return value
  } else if (format === 'date') {
    return formatLocaleDate(value)
  } else if (format === '%') {
    return parseFloat(value).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces })
  } else {
    return parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces })
  }
}