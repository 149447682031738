import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserFavouriteFilterActions } from 'actionsets'
import { compose } from 'utils'
import { LabeledSelect } from 'components';
import { Dependent } from 'containers/shared'
import FavouritesDialog from './FavouritesDialog'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Favorite'

export class UserFavouriteFilterSelect extends Component {
  constructor(props) {
    super(props)
    UserFavouriteFilterActions.bindActions(this)
  }

  state = { dialogOpen: false }

  dependsOn() {
    return this.actions.index({ filter: { path: this.props.path }, page: 1, pageSize: 2000 })
  }

  dependenciesMet() {
    return !!this.props.userFavouriteFilters
  }

  get userFavouriteFilters() {
    return (this.props.userFavouriteFilters || [])
  }

  get options() {
    return [{ id: "clear", name: "--clear--" }, ...this.userFavouriteFilters]
  }

  componentDidMount = () => {
    const defaultFav = this.userFavouriteFilters.find(uff => uff.isDefault)
    if (defaultFav) {
      this.handleChange({ target: { value: defaultFav.id } })
    }
  }

  handleChange = ({ target: { value } }) => {
    const blank = this.props.appliesTo.reduce((obj, key) => { obj[key] = undefined; return obj }, {})
    const selected = this.userFavouriteFilters.find(uff => uff.id === value) || {}
    this.props.onFilterChange({ ...this.props.filter, ...blank, ...selected.filter })
    this.setState({ selectedId: value === "clear" ? null : value })
  }

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false })
  }

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true })
  }

  render = () => {
    const { ...props } = this.props
    return (
      <div style={{ display: 'inline-block', marginRight: 10 }}>
        <IconButton onClick={this.handleOpenDialog} style={{ display: 'inline-block', marginTop: 20, marginRight: 4, padding: 0 }}>
          <EditIcon />
        </IconButton>
        <LabeledSelect label="Favourite" options={this.options} style={{ minWidth: 100 }} onChange={this.handleChange} value={this.state.selectedId} keyField="id" textField="name" />
        <FavouritesDialog open={this.state.dialogOpen} {...props} onClose={this.handleCloseDialog} />
      </div>
    )
  }

}

export default compose(
  Dependent({ loader: true, clearOnLoad: true, wrapperStyle: { display: "inline-block" } }),
  connect(({ userFavouriteFilters }) => userFavouriteFilters),
)(UserFavouriteFilterSelect)