import React, {Component} from 'react'
import withStyles from 'styles'
import {withContext} from 'utils/Context'
import LoadedContext from 'components/LoadedContext'

class LoadedContent extends Component {
  render = () => {
    const {loaded, classes, className, ...props} = this.props
    return (
      <div {...props} className={classes({content: true, loading: !loaded}, className)} />
    )
  }

}

const styles = {
  content: {
    transition: 'filter 1000ms'
  },
  loading: {
    filter: 'opacity(50%)',
    pointerEvents: 'none'
  }
}

export default withContext(LoadedContext)(withStyles(styles)(LoadedContent))