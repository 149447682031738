import React, {Component} from 'react'
import withStyles from 'styles'
import ReactDOM from 'react-dom'

export class PageContainer extends Component {
  state = {mounted: false, minHeight: 0}

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({mounted: true})
    })
  }

  componentDidUpdate = () => {
    if (this.rightRef) {
      const rightDOM = ReactDOM.findDOMNode(this.rightRef)
      const minHeight = rightDOM.offsetHeight + rightDOM.offsetTop - 50
      if (this.state.minHeight !== minHeight) {
        this.setState({minHeight})
      }
    }
  }

  render = () => {
    const {classes, width, ...props} = this.props
    const {minHeight} = this.state
    return (
      <PageContext.Provider value={{rightRef: ref => this.rightRef = ref}}>
        <div style={{minHeight}} className={classes({leftSide: !!this.rightRef, pageContainer: true, transition: !this.state.mounted, [`${width}Width`]: !!width})} {...props} />
      </PageContext.Provider>
    )
  }

}

export const PageContext = React.createContext({})

const styles = theme => ({
  pageContainer: {
    position: 'relative',
    transition: 'filter 1000ms, margin 225ms',
    maxWidth: 800,
    '@media(min-width: 600px)': {
      margin: '30px 30px 30px 50px',
    },
    '@media(min-width: 750px)': {
      margin: '53px 50px 50px 80px',
    },
    '@media(min-width: 900px)': {
      margin: '53px 80px 80px 128px',
    },
    '@media(min-width: 1280px)': {
      width: 800,
    },
    margin: '16px 0px 16px 10px',
    '& > ul': {
      marginRight: 10,
    }
  },
  transition: {
    filter: 'opacity(0%)'
  },
  xlWidth: {
    width: 'auto',
    maxWidth: 'none'
  },
  lgWidth: {
    maxWidth: 1100,
    '@media(min-width: 1366px)': {
      width: 1100,
    },
  },
  leftSide: {
    '@media(min-width: 1366px)': {
      maxWidth: 'calc(80vw - 450px)'
    }
  },
})

export default withStyles(styles)(PageContainer)
