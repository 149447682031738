import React, { Component } from 'react'
import PropTypes from 'prop-types';
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

export class Pagination extends Component{

  static propTypes = {
    totalPages:     PropTypes.number.isRequired,
    page:           PropTypes.number.isRequired,
    onPageSelected: PropTypes.func.isRequired,
    style:          PropTypes.object,
    linkStyle:      PropTypes.object,
    showNext:       PropTypes.bool,
    showPrev:       PropTypes.bool,
    showFirst:      PropTypes.bool,
    showLast:       PropTypes.bool,
    lookAround:     PropTypes.number,
    startAdornment: PropTypes.node,
  }

  static defaultProps = {
    showNext:   true,
    showPrev:   true,
    showFirst:  true,
    showLast:   true,
    lookAround: 2,
    startAdornment: null
  }

  renderButtons = () => {
    const { showNext, showPrev, showFirst, showLast, page, totalPages, lookAround } = this.props
    if(totalPages <= 1) return false

    const buttons = []

    if(showFirst && totalPages > 1) buttons.push(this.renderButton('First', 1))
    if(showPrev && page > 1)        buttons.push(this.renderButton(<ChevronLeft className={this.props.classes.chevron}/>, page - 1))

    for(let index = page - lookAround; index <= page + lookAround; index++){
      if(index < 1 || index > totalPages) continue
      buttons.push(this.renderButton(index, index, index === page))
    }
    if(showNext && page < totalPages) buttons.push(this.renderButton(<ChevronRight className={this.props.classes.chevron}/>, page + 1))
    if(showLast && totalPages > 1)    buttons.push(this.renderButton('Last', totalPages))

    return buttons
  }

  renderButton = (text, index, disabled=false) =>
    <Button className={this.props.classes('btn', {active: disabled})} size='small' onClick={() => this.props.onPageSelected(index)} disabled={disabled} key={`${text}-${index}`}>{text}</Button>


  render = () =>
    <div className={this.props.classes.pagination}>
      <div className={this.props.classes.startAdornment}>
        { this.props.startAdornment }
      </div>
      <div className={this.props.classes.pageButtons}>
        { this.renderButtons() }
      </div>
    </div>
}


const styles = theme => {
  return {
    pagination: {
      flex: '1 1 auto',
      display: 'flex',
      background: 'rgba(0, 0, 0, 0.025)',
      borderRight: `6px solid ${theme.palette.primary.main}`,
      fontSize: '0.8rem',
      marginTop: 5,
      justifyContent: 'space-between',
    },
    pageButtons: {
    },
    startAdornment: {
      width: 'auto',
      marginLeft: 6,
    },
    btn: {
      padding: 5,
      minWidth: 25
    },
    chevron: {
      color: theme.palette.primary.main
    },
    active: {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.text.secondary,
      background: theme.palette.background.default,
      boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.05)'
    },
    paginationButton: {
      margin: '5px 2px',
      borderRadius: '3px',
      padding: '5px',
      cursor: 'pointer'
    },
    paginationButtonDisabled: {
      border: '1px solid silver',
      cursor: 'inherit',
      color: theme.palette.primary.main,
      background: 'white',
    }
  }
}
export default withStyles(styles)(Pagination)
