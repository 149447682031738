class AuthorizationProxy{

  set store(value){
    this._store = value
  }

  get store(){
    this._store = this._store || require('store').default
    return this._store
  }

  get userId(){
    return this.user.id
  }

  get user(){
    const user = this.store.getState().tokens.currentUser
    return user
  }

  get admin(){
    return (this.user.role === 'admin')
  }

  get member(){
    return (this.user.role === 'member')
  }
}

export const Authorization = new AuthorizationProxy()
