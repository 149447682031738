import { bindActionCreators } from 'redux'
import { createReducer, unique } from 'utils'
/**
 * An ActionSet is a basic abstraction over plain-old redux.
 * It still uses the same actions, reducers and constants as you would ordinarily,
 * but it changes where you put these so that actions and their corresponding reducers
 * are a single cohesive unit.
 *
 * We have found that keeping related actions, reducers and constants together helps
 * avoid the need to repeatedly jump between multiple files when defining a new action.
 *
 * To understand ActionSets it is a good idea to ensure you understand Redux first.
 * I.e. follow the guide here
 *   https://redux.js.org/
 *
 * To illustrate the difference between plain old redux and an ActionSet consider the following example:
 *

## EXAMPLE REDUX ##
* For traditional Redux you might define the following in 3 different files

// Constants //
export const ADD_TODO = 'ADD_TODO'

// Action   //
const nextTodoId = 0
export const addTodo = text => {
  return {
    type: ADD_TODO,
    id: nextTodoId++,
    text
  }
}

// Initial State //
const initialState = []

// Reducer //
const todos = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TODO':
      return [
        ...state,
        {
          id: action.id,
          text: action.text,
          completed: false
        }
      ]
  }
}

## EXAMPLE ACTIONSET ##
* In an ActionSet these four parts are all defined closely together.
* Note that in the below example:
*  * the constant ADD_TODO is automatically defined and attached to `this`
*  * The initial state is automatically detected and used as a base for your reducers
*  * The body of the reducer and the action creator are still identical to the plain old redux example
*  * If you fail to define a reducer or get the name of a constant wrong an exception will be thrown.
*

const nextTodoId = 0
class TodoActionSet extends ActionSet{
  static initialState = {}

  static add(creator, reducer){
    creator(text => {
      return {
        type: this.ADD_TODO,
        id: nextTodoId++,
        text
      }
    })

    reducer({
      [this.ADD_TODO]: (state, action) => {
        return [
          ...state,
          {
            id: action.id,
            text: action.text,
            completed: false
          }
        ]
      }
    })
  }
}

// To access the actions for dispatch and the reducers, use the following:
const TodoActions = new TodoActionSet()

// Get reducers like this
todoReducers = TodoActions.reducers

// Dispatch actions like this
dispatch(TodoActions.add("My new To Do"))

// Bind action creators to a context like this.

const context = { dispatch: () => {...}}

TodoActions.bindActions(context)
context.actions.add("My new To Do")

// or

TodoActions.bindActions(context, 'todos')
context.actions.todos.add("My new To Do")

*
* Additional text constants to be used in your action can be defined using the optional third argument
* to an action, named constants. E.g.
*
* static add(creator, reducer, constants){
*    constants('FOO', 'BAR')
*    ...
*    # Now you can use `this.FOO` and `this.BAR` in your creators and reducers.
*    # Using constants defined like this is much safer than using raw strings as it will catch any typos
*    # in the name of your reducers as soon as the ActionSet is constructed
* }
*/
export default class ActionSet{

  static actionSetSequence = 0

  constructor(){
    this.initialState = this.protoChain.reduce(
      (initialState, constructor) => {
        return {...initialState, ...constructor.initialState}
      },
      {}
    )
    this.reducerSets = []
    this.sequenceNo = (ActionSet.actionSetSequence += 1)
    this.actionNames.forEach(actionName => {
      try{
        if(actionName !== '__proto__'){
          this.createAction(actionName)
        }
      }catch(err){
        throw new Error(`Error while creating action ${actionName} ${err}`)
      }
    })
    this.actions = {}
    this.actionNames.forEach(name => {
      this.actions[name] = this[name]
    })

    this.combineReducers()
  }

  get protoChain(){
    let chain = []
    let { constructor } = this
    while(constructor !== ActionSet){
      chain = [constructor].concat(chain)
      constructor = constructor.__proto__
    }
    return chain
  }

  combineReducers(){
    let reducers = {}
    this.reducerSets.forEach(set => reducers = {...reducers, ...set})
    if(reducers[undefined]){
      throw new Error('Reducer with undefined type defined. Did you forget to define a constant?')
    }
    this.reducerNames = Object.keys(reducers)
    this.reducers = createReducer(this.initialState, reducers)
  }

  extractReducers(defaultName, reducers){
    if(typeof(reducers) === 'function'){
      return { [defaultName]: reducers }
    }else{
      return reducers
    }
  }

  applyConstantsMiddleware(constants){
    this.constructor.constantsMiddleware.forEach(mw => constants = mw(constants))
    return constants
  }

  createAction(actionName){
    const actionCreator   = this.constructor[actionName]
    const actionConstant  = this.constantize(actionName)
    const setName         = this.constantize(this.constructor.name)

    this.defineConstant(actionConstant)

    const dependencies = { creator: null, reducer: null}

    let actionConstants = (...suffixes) => suffixes.forEach(suffix => {
      this.defineConstant(`${actionConstant}_${suffix}`, `${this.sequenceNo}.${setName}.${actionConstant}.${suffix}`)
      actionConstants[suffix] = `${this.sequenceNo}.${setName}.${actionConstant}.${suffix}`
    })
    actionConstants.ACTION = `${this.sequenceNo}.${setName}.${actionConstant}`
    if(this.constructor.constantsMiddleware)
      actionConstants = this.applyConstantsMiddleware(actionConstants)

    actionCreator.bind(this)(
      creatorFunc => dependencies.creator = creatorFunc,
      reducers    => dependencies.reducer = this.extractReducers(actionConstant, reducers),
      actionConstants
    )

    if(!dependencies.creator){
      throw new Error(`Action ${actionName} does not define a creator`)
    }
    if(!dependencies.reducer){
      throw new Error(`Action ${actionName} does not define a reducer`)
    }

    this[actionName] = dependencies.creator
    this.reducerSets.push(dependencies.reducer)
  }

  defineConstant(key, value){
    const asConst = this.constantize(key)
    const asConstValue = `${this.sequenceNo}.${this.constantize(this.constructor.name)}.${asConst}`
    this[asConst] = value || asConstValue
  }

  constantize(value){
    return `${value}`.replace(/[a-z][A-Z]/g, (str) => `${str[0].toUpperCase()}_${str[1].toUpperCase()}`).toUpperCase()
  }

  get actionNames(){
    const propertyNames = unique(this.protoChain.reduce((propertyNames, constructor) =>
      Object.getOwnPropertyNames(constructor).concat(propertyNames), ['resetState']
    ))
    return propertyNames.filter(propName => {
      try{
        const value = this.constructor[propName]
        return typeof value === 'function'
      }catch(err){}
      return false
    })
  }

  bindActions(context, namespace){
    let actions = context.actions = context.actions || {}
    if(!context.props.dispatch){
      const scope = this.constructor.name
      const subject = scope.replace('ActionSet', '')
      throw new Error(`Cannot bind to ${scope}. Component is not connected (has no dispatch prop).
Maybe you are missing something like:
  import { connect } from 'react-redux'
  export default connect(({${subject.toLowerCase()}}) => ${subject.toLowerCase()})(${subject})`
)
    }
    if(namespace){
      actions = actions[namespace] = actions[namespace] || {}
    }
    Object.assign(actions, bindActionCreators(this.actions, context.props.dispatch))
  }

  static resetState(creator, reducer){
    creator(() => {
      return {
        type: this.RESET_STATE
      }
    })

    reducer({
      [this.RESET_STATE]: () => this.initialState
    })
  }
}