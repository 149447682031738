import React, { Component } from 'react'

export class Nav extends Component{

  render = () =>
    <nav>
    </nav>

}

export default Nav