import React, { Component } from 'react'
import { LabeledSelect } from 'components';
import { MenuItem, Checkbox, ListItemText } from '@material-ui/core'

const SELECT_ALL = "select-all"

export class LabeledSelectMulti extends Component {
  get keyField() {
    return this.props.keyField || 0
  }

  get textField() {
    return this.props.textField || 1
  }

  get renderText() {
    return this.props.renderText || this.defaultRenderText
  }

  defaultRenderText = (option) => {
    return option ? option[this.textField] : ""
  }

  mapMenuItems = (option) =>
    <MenuItem key={option[this.keyField]} value={option[this.keyField]}>
      <Checkbox checked={!!((this.props.value || '').split('-')).find(f => f === option[this.keyField])} />
      <ListItemText primary={this.renderText(option)} />
    </MenuItem>

  handleMultiSelectChange = field => ({ target: { value } }) => {
    if (!value.some(v => v === SELECT_ALL)) {
      this.props.onChange({ target: { value: value.join('-') } })
    } else if ((this.props.value || '').split('-').length === this.props.options.length) {
      this.props.onChange({ target: { value: "" } })
    } else {
      this.props.onChange({ target: { value: this.props.options.map(o => o[this.keyField]).join('-') } })
    }
  }

  renderSelectAll = () =>
    <MenuItem key={SELECT_ALL} value={SELECT_ALL} onClick={this.handleSelectAll}>
      <Checkbox checked={(this.props.value || '').split('-').length === this.props.options.length} />
      <ListItemText primary="All" />
    </MenuItem>

  render = () => {
    const { options, renderText, ...props } = this.props
    return <LabeledSelect multiple {...props}
      value={(this.props.value || '').split('-').filter(e => !!e)}
      onChange={this.handleMultiSelectChange("fundCategoryIds")}
      renderValue={selected => selected.map(id => this.renderText(options.find(f => f[this.keyField] === id))).join(', ')}
    >
      {this.props.selectAll && this.renderSelectAll()}
      {options.map(this.mapMenuItems)}
    </LabeledSelect>
  }
}

export default LabeledSelectMulti