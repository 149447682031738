import React, { Component } from 'react'

export class LogoInner extends Component{

  static defaultProps = {
    fill: '#FFFFFF',
    bounds: [0,0,160,160],
    offsetX: 26.25,
    offsetY: 26.25,
    width: 106.5,
    height: 106.5,
  }

  render = () =>
    <svg version="1.1" viewBox="0 0 160 160" style={this.props.style || {}} width={this.props.width} height={this.props.height}>
      <g id="Layer_1_1">
        <path fill={this.props.fill} d="M85.295,122.889c0,0-24.831-10.668-48.932-1.764c0,0,24.066-31.314,24.066-92.967
  c0,0,5.86,27.107,19.383,80.51c0,0,0.777,3.598,3.444,4.617c2.15,0.83,4.21,0.29,5.785-1.689
  c3.168-3.957,12.177-18.141,16.563-61.416c0,0,11.783,44.703,20.102,73.166C125.708,123.346,108.754,131.928,85.295,122.889z"/>
      </g>
    </svg>

}

export default LogoInner

