import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TokenActions, SnackbarActions } from 'actionsets'
import { CenteredCard, ErrorBanner } from 'components'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'

export class Confirm extends Component{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this)
  }

  componentDidMount = () => {
    this.submit()
  }

  get token(){
    return window.location.search.replace(/^.*?token=([^$&]+).*/,'$1')
  }

  submit = async () => {
    await this.actions.confirm({token: this.token})
    await this.actions.verify()
    this.actions.show("Your account has been confirmed")
    this.props.history.push('/')
  }

  render = () =>
    <CenteredCard >
      <CardContent>
        {this.props.errors.confirm && <ErrorBanner>{this.props.errors.confirm.title}</ErrorBanner>}
        <Typography type='h5'>Confirming your account...</Typography>
      </CardContent>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(Confirm)