import ActionSet from './ActionSet'
import * as Actions from '.' // eslint-disable-line

export class ResetActionSet extends ActionSet{

  static initialState = {}

  static resetState(creator, reducer, constants){
    creator(() => dispatch => {
      dispatch({type: constants.ACTION})
    })

    reducer({})
  }
}

export default new ResetActionSet()