import React, { Component } from 'react'
import { withContext } from 'utils/Context'
import LoadedContext from 'components/LoadedContext'
import Typography from '@material-ui/core/Typography'
import HeaderLoader from 'components/HeaderLoader'

class LoadedHeader extends Component {

  static defaultProps = {
    variant: 'h5',
    separator: ' - '
  }

  render = () => {
    const {loaded, name, separator, children, ...props} = this.props
    return (
      <Typography {...props}>
        {children}{children && (!loaded || name) && separator}
        {loaded ? name : <HeaderLoader />}
      </Typography>
    )
  }
}

export default withContext(LoadedContext)(LoadedHeader)
