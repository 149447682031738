import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FundActions, SnackbarActions, FundCategoryActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FundIcon from '@material-ui/icons/ShowChart'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, compact, flatten } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { MenuItem } from '@material-ui/core'
import {
  Pagination,
  ErrorBanner,
  SearchCombo,
  LabeledCheckbox,
  Select,
} from 'components'
import { Authorization } from 'utils'
import withStyles from 'styles'

export class List extends Component{

  constructor(props){
    super(props)
    FundActions.bindActions(this)
    FundCategoryActions.bindActions(this, 'fundCategory')
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return Promise.all([
      this.loadFunds(),
      this.actions.fundCategory.index({
        options: {
        fields: { fundCategories: 'id,name' },
        page: { number: 1, size: 2000 }
        }
      })
    ])
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.query !== this.props.query) {
      this.loadFunds()
    }
  }

  loadFunds = () => {
    return this.actions.index({
      page: this.props.page,
      filter: this.props.filter,
      include: 'fundCategories',
      fields: {funds: 'name,closedAt,telCode,fundCategories', fundCategory: 'name'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showFund = id => () => {
    this.props.history.push(`/funds/${id}`)
  }

  editFund = id => event => {
    this.props.history.push(`/funds/${id}/edit`)
    event.stopPropagation()
  }

  deleteFund = id => event => {
    this.actions.destroy({id})
                .then(this.loadFunds)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get funds(){
    return this.props.funds
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadFunds()
  }

  get filter() {
    const { filter } = this.props
    filter.category = compact(flatten([filter.category])).map(id => String(id))
    return filter
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  handleFilterChange = filter => {
    this.props.onFilterChange(filter)
  }

  mapFundCategoryMenuItems = ({ id, name }) =>
    <MenuItem key={id} value={id}>
      <ListItemText primary={name} />
    </MenuItem>

  renderFundListItem = ({id, name, telCode, closedAt, fundCategories=[]}) =>
    <ListItem button onClick={this.showFund(id)} key={id}>
      <ListItemIcon>
        <FundIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            <span className={this.props.classes.telCode}>{telCode || '-' }</span>
            <span className={this.props.classes.fundName}>
              {name}<br/>
              <span className={this.props.classes.fundCategories}>{fundCategories.map(({name}) => name).join(', ')}</span>
            </span>
            {
              closedAt ?
                <span className={this.props.classes.closedBadge}>Closed as at {closedAt}</span> :
                false
            }
          </>
        }
      />
      <ListItemSecondaryAction>
        {
          Authorization.admin &&
          <>
            <IconButton onClick={this.editFund(id)}><EditIcon/></IconButton>
            <IconButton onClick={this.deleteFund(id)}><DeleteIcon/></IconButton>
          </>
        }
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearchCombo = () =>
    <SearchCombo onFilterChange={this.handleFilterChange} filter={this.filter}>
      <LabeledCheckbox member='showClosed'/>
      <Select
        multiple
        member='category'
        label="Fund Categories"
        renderValue={selected => selected.map(id => this.props.fundCategories.find(f => f.id === id).name).join(', ')}
      >
        {this.props.fundCategories.map(this.mapFundCategoryMenuItems)}
      </Select>
    </SearchCombo>

  render = () =>
    <PageContainer>
      <ActionHeader title="Funds">
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push('/funds/new')}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={this.renderSearchCombo()}/>
      <MuiList dense>
        {this.funds.map(this.renderFundListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = theme => ({
  closedBadge: {
    display: 'inline-block',
    margin: "0 10px",
    background: "#d0d0d0",
    padding: "4px 8px",
    borderRadius: "36px",
  },
  fundName: {
    display: 'inline-block'
  },
  fundCategories: {
    fontStyle: 'italic',
    color: '#777'
  },
  telCode: {
    margin: "0 5px",
    display: "inline-block",
    fontWeight: "bold",
    background: "#dbdbdb",
    width: "73px",
    textAlign: "center",
    borderRadius: "14px",
    float: "left",
    lineHeight: "1.6",
    paddingTop: "1px",
  }
})

export default compose(
  Dependent({loader: true}),
  connect(({funds, fundCategories}, {page, filter}) => ({
      filter: {
        showClosed: false,
        ...filter
      },
      ...fundCategories,
      ...funds,
      page
    }
  )),
  connectQueryString('funds'),
  withStyles(styles),
)(List)