import React, { Component, Fragment } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { formatLocaleDateTime } from 'utils'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { ConfirmationDialog } from 'components'

export class CommentsList extends Component{
  static propTypes = {
    comments: PropTypes.array,
    readonly: PropTypes.bool,
    onDeleteComment: PropTypes.func,
    onUpdateComment: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      showDelete: false,
      showEdit: false,
      currentComment: null,
    }
  }

  get comments() {
    return this.props.comments || []
  }

  showDeleteCommentHandler = (comment) => () => {
    this.setState({currentComment: comment, showDelete: true})
  }

  hideDeleteCommentHandler = () => {
    this.setState({currentComment: null, showDelete: false})
  }

  showEditCommentHandler = (comment) => () => {
    this.setState({currentComment: comment, showEdit: true})
  }

  hideEditCommentHandler = () => {
    this.setState({currentComment: null, showEdit: false})
  }

  handleDeleteComment = () => {
    this.props.onDeleteComment(this.state.currentComment.id)
    this.setState({currentComment: null, showDelete: false})
  }

  handleUpdateComment = () => {
    this.props.onUpdateComment(this.state.currentComment.id, this.state.currentComment.content)
    this.setState({currentComment: null, showEdit: false})
  }

  currentCommentChangedHandler = (event) => {
    this.setState({currentComment: {...this.state.currentComment, content: event.target.value}})
  }

  render() {
    return (
      <Fragment>
        {
          this.state.showDelete &&
          <ConfirmationDialog title="Delete comment?" confirmLabel='Delete'
            open={true}
            onConfirm={this.handleDeleteComment}
            onCancel={this.hideDeleteCommentHandler} maxWidth={'lg'}>
              <Typography component='pre'>{this.state.currentComment.content}</Typography>
          </ConfirmationDialog>
        }
        {
          this.state.showEdit &&
          <ConfirmationDialog title="Edit comment" confirmLabel='Save'
            open={true}
            onConfirm={this.handleUpdateComment}
            onCancel={this.hideEditCommentHandler} maxWidth={'lg'}>
              <TextField fullWidth multiline={true} rows={4} onChange={this.currentCommentChangedHandler} value={this.state.currentComment.content} />
          </ConfirmationDialog>
        }
        <List dense>
          {
            this.comments.map((comment) => {
              const {id, content, createdAt, user: {name}} = comment
              const primary = `${formatLocaleDateTime(createdAt)} ${name} said:`

              return <ListItem alignItems="flex-start" key={id}>
                <ListItemText primary={
                  <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>{primary}</Typography>
                } secondary={
                  <Typography component='pre'>{content}</Typography>
                }/>
                {
                  !this.props.readonly &&
                  <ListItemSecondaryAction>
                    {this.props.onUpdateComment &&
                    <IconButton edge="end" aria-label="Edit" onClick={this.showEditCommentHandler(comment)}>
                      <EditIcon />
                    </IconButton>}
                    {this.props.onDeleteComment &&
                    <IconButton edge="end" aria-label="Delete" onClick={this.showDeleteCommentHandler(comment)}>
                      <DeleteIcon />
                    </IconButton>}
                  </ListItemSecondaryAction>
                }
              </ListItem>
            })
          }
        </List>
      </Fragment>
    )
  }
}

export default CommentsList