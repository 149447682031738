import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FileImportActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'

export class Show extends Component{

  constructor(props){
    super(props)
    FileImportActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id)
  }

  dependenciesMet(){
    return !!this.fileImport.id
  }

  get id(){
    return this.props.match.params.id
  }

  get fileImport(){
    return this.props.fileImport
  }

  handleDownloadFile = url => () =>
    window.open(url, "_blank")

  renderError = error => {
    if(!error) return false
    try{
      error = JSON.parse(error)
    }catch(err){
      //Not JSON
    }
    if(!Array.isArray(error)) return String(error)
    return <ul>
      {error.map((errStr, idx) => <li key={idx}>{errStr}</li>)}
    </ul>
  }
  render = () =>
    <PageContainer>
      <ActionHeader title={`File Import - ${this.fileImport.fileName}`}/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <IconButton onClick={this.handleDownloadFile(this.fileImport.fileUrlPath)}><DownloadIcon/></IconButton>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>File name</TableCell>
              <TableCell>{String(this.fileImport.fileName)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Import type</TableCell>
              <TableCell>{String(this.fileImport.importType)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Manual</TableCell>
              <TableCell>{String(this.fileImport.manual)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Imported at</TableCell>
              <TableCell>{String(this.fileImport.importedAt)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Status</TableCell>
              <TableCell>{this.fileImport.status && String(this.fileImport.status)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Error</TableCell>
              <TableCell>{this.renderError(this.fileImport.error)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
}


export default connect(({fileImports}) => fileImports)(Dependent({loader: true, clearOnLoad: true})(Show))