export function autobind(object, context){
  context = context || object
  Object.keys(object).forEach(function(key) {
    let service = object[key];
    if (typeof(service) === 'function'){
      context[key] = service.bind(context)
    }
  });
  return context;
}

export const CustomPropTypes = {
  arrayOfElementType(elementType){
    const validator = (isRequired, props, propName, component) => {
      if(!elementType)
        return 'Cannot have an element type of null or undefined'

      let propsArray = props[propName]
      if(!Array.isArray(propsArray)){
        propsArray = propsArray ? [propsArray] : []
      }
      if(isRequired && propsArray.length === 0){
        return new Error(`Element of type ${elementType.name} is required in component ${component}`)
      }
      for(let index = 0; index < propsArray.length; index++){
        const prop = propsArray[index]
        if(!prop.type || prop.type !== elementType){
          return new Error(`Element ${prop} ${prop.type} is not of type ${elementType.name} in component ${component}`)
        }
      }
      return null
    }
    const notRequired = validator.bind(null, false)
    notRequired.isRequired = validator.bind(null, true)
    return notRequired
  }
}

export const compose = (...composedFuncs) => target =>
  composedFuncs.reduce((acc, fnc) => fnc(acc), target)

export const eventKeyMatches = (event, key) => {
  if (event.key !== undefined) {
    return event.key === key.key
  } else if (event.keyCode !== undefined) {
    return event.keyCode === key.keyCode
  } else if (event.which !== undefined) {
    return event.keyCode === key.keyCode
  }
}

export const KEYS = {
  enter: {keyCode: 13, key: 'Enter'},
  escape: {keyCode: 27, key: 'Escape'},
  tab: {keyCode: 9, key: 'Tab'}
}
