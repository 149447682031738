import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MetricTypeActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { Authorization } from 'utils'

export class Show extends Component {

  constructor(props) {
    super(props)
    MetricTypeActions.bindActions(this)
  }

  dependsOn() {
    return this.actions.show(this.id)
  }

  dependenciesMet() {
    return !!this.metricType.id
  }

  get id() {
    return this.props.match.params.id
  }

  get metricType() {
    return this.props.metricType
  }

  textForFormat = (format) => {
    return (format === "%") ? "Percentage" :
      (format === "#") ? "Number" : (format || '');
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Metric Type - ${this.metricType.displayName}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 30 }} variant='head'>Actions</TableCell>
            <TableCell>
              {
                Authorization.admin &&
                <Link to={`/metric_types/${this.metricType.id}/edit`}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Display Name</TableCell>
            <TableCell>{String(this.metricType.displayName)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>System Name</TableCell>
            <TableCell>{String(this.metricType.name)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Format</TableCell>
            <TableCell>{String(this.textForFormat(this.metricType.format))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Decimal Places</TableCell>
            <TableCell>{String(this.metricType.decimalPlaces || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Annualise</TableCell>
            <TableCell>{String(this.metricType.annualise)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
}


export default connect(({ metricTypes }) => metricTypes)(Dependent({ loader: true, clearOnLoad: true })(Show))