import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, deepSet, uuid } from 'utils'
import { ActionHeader, PercentageField, ConfirmationDialog, LabeledSelect } from 'components'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import withStyles from 'styles'
import { Button } from '@material-ui/core'

export class FundInceptionInitialValueSetup extends Component {

  state = { open: false }

  get values() {
    return this.state.values || []
  }

  handleAddRow = () => {
    this.setState({
      values: [
        ...this.values,
        { uuid: uuid() }
      ]
    })
  }

  handleDeleteRow = rmIdx => () => {
    this.setState({ values: this.values.filter((_, i) => i !== rmIdx) })
  }

  handleOpen = () => {
    let values = (this.props.value || [])
    if (values.length === 0) {
      values = [{ uuid: uuid() }]
    }
    this.setState({ open: true, values })
  }

  handleConfirm = () => {
    this.setState({ open: false })
    this.props.onChange && this.props.onChange({ target: { value: this.values } })
  }

  handleCancel = () => {
    this.setState({ open: false })
  }

  handleRowChange = (field, updateIdx) => ({ target: { value } }) => {
    this.setState({
      values: this.values.map((row, i) => updateIdx === i ?
        deepSet(value, field, this.values[updateIdx]) : row
      )
    })
  }

  get custodians() {
    return this.props.custodians || []
  }

  get metricTypes() {
    return (this.props.metricTypes || []).filter(mt => (mt.category === 'fund_returns' && mt.annualise))
  }

  renderValue = ({ id, uuid, initialValue, custodianId, metricTypeId }, index) =>
    <ListItem key={id || uuid} className={this.props.classes.listItem}>
      <LabeledSelect style={{ minWidth: '120px' }} label="Custodian" keyField="id" textField="name" options={this.custodians} value={custodianId} onChange={this.handleRowChange('custodianId', index)} />
      <LabeledSelect style={{ minWidth: '120px' }} label="Metric Type" keyField="id" textField="displayName" options={this.metricTypes} value={metricTypeId} onChange={this.handleRowChange('metricTypeId', index)} />
      <PercentageField style={{ minWidth: '120px' }} label='Initial Value' value={initialValue} onChange={this.handleRowChange('initialValue', index)} />
      <IconButton onClick={this.handleDeleteRow(index)}>
        <ClearIcon />
      </IconButton>
    </ListItem>

  render = () =>
    <Fragment>
      <Button onClick={this.handleOpen} variant="contained" color="primary">Edit Initial Values</Button>
      <ConfirmationDialog
        title={<ActionHeader title="Edit Initial Values">
          <IconButton onClick={this.handleAddRow}>
            <AddIcon />
          </IconButton>
        </ActionHeader>}
        confirmLabel='OK'
        open={this.state.open}
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel} maxWidth={'lg'}>
        <List className={this.props.classes.valueSetup}>
          {this.values.map(this.renderValue)}
        </List>
      </ConfirmationDialog>
    </Fragment>
}

const styles = {
  valueSetup: {
    margin: '10px 0',
    flex: '1 1 500px'
  },
  listItem: {
    padding: 0,
    '& > div': {
      flex: '1 1 120px',
      margin: '0 10px'
    },
    '& :first-child': {
      marginLeft: 0
    },
    '& > :last-child': {
      marginRight: 0
    }
  }
}

export default compose(
  withStyles(styles),
  connect(({ custodians }) => custodians),
  connect(({ metricTypes }) => metricTypes),
)(FundInceptionInitialValueSetup)