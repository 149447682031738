export function compact(collection){
  return collection.filter(x => x)
}

export function flatten(collection){
  return collection.reduce(function(prev, inner) {
    inner = Array.isArray(inner) ? inner : [inner]
    const  multiLevel = (inner).some(Array.isArray);
    return prev.concat(multiLevel ? flatten(inner) : inner);
  },[]);
}

export function unique(collection){
  return collection.filter((x, i, a) => a.indexOf(x) === i)
}

export function collectionPresent(collection){
  return collection != null && !!collection.length
}

export function any(collection){
  return collection != null && collectionPresent(Object.values(collection).filter(x => x))
}

export function groupCollectionBy(collection, ...by){
  const grouped = {}
  collection.forEach(item => {
    const key = by.map(byValue => `${item[byValue]}`).join('::')
    grouped[key] = grouped[key] || []
    grouped[key].push(item)
  })
  return grouped
}

/**
 * Reach deep into a nested object and extract a property at a given path
 * E.g.
 *   deepGet('hello.world.foo.bar', {hello: { world: {foo: {bar: 3}}}}) => 3
 *
 * Silently ignores the remainder of the path if any property is missing
 *
 * E.g.
 *   deepGet('hello.world.foo.bar.no.exist', {hello: { world: {foo: {bar: 3}}}})  => null
 */
export function deepGet(path, object) {
  return path.split(/(?:\[(?=\d)|(?!=\d)\]\.?|\.)/).filter(x => x !== "").reduce((memo, part) => memo ? memo[part] : null, object)
}

/**
 * Set a property deeply inside a nested object
 * Every object on the path to the property is cloned
 * E.g.
 *   deepSet(4, 'hello.world.foo.baz', 4, {}) =>
 *     {"hello":{"world":{"foo":{"baz":4}}}}
 *
 */
export function deepSet(value, path, object) {
  const attributeChain = path.split(/\[(?=\d)|(?!=\d)\]\.?|\./).filter(x => x !== "")
  const updatedContext = {...object}
  const valueObject = attributeChain.slice(0, -1).reduce((result, att) => (result[att] = Array.isArray(result[att]) ? [...result[att]] : {...result[att]}), updatedContext)
  valueObject[attributeChain[attributeChain.length-1]] = value
  return updatedContext
}

export function indexBy (list, by='id') {
  return list.reduce(((accumulator, item) => ({ ...accumulator, [`${item[by]}`]: item })), {})
}