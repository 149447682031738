import Nonce from './Nonce'

class MicrosoftService {
  constructor() {
    this.authPopup = null
    this.callbacks = new Set()
  }

  handleAuthResponse = (event) => {
    if(event.source !== this.authPopup) {
      return
    }

    let { state } = event.data
    let nonce = global.localStorage.microsoftOAuthNonce

    if(nonce !== state) {
      this.replyToListeners({ error: 'state_mismmatch', errorDescription: 'Potential CSRF attack' })
      return
    }

    // TODO maybe we want to standardize this response over multiple Auth providers
    let authHash = this.authHashFromReply(event.data)
    this.replyToListeners(authHash)
    this.authPopup.close()
  }

  authHashFromReply = (data) => {
    let { error, error_description, code } = data
    if(error) {
      return { error, errorDescription: error_description }
  } else {
      return { authCode: code, redirectUri: this.redirectUri() }
    }
  }

  replyToListeners = (payload) => {
    this.callbacks.forEach((callback) => { callback(payload) })
    this.callbacks.clear()
    window.removeEventListener('message', this.handleAuthResponse)
  }

  authorize = (callback, popup = false) => {
    const nonce = Nonce.generate()
    if(popup) {
      this.callbacks.add(callback)
      if(!this.authPopup || this.authPopup.closed) {
        window.addEventListener('message', this.handleAuthResponse, false)
        global.localStorage.microsoftOAuthNonce = nonce
        this.authPopup = window.open(this.authUrl(nonce), 'MicrosoftAuth')
      }
      this.authPopup.focus()
    } else {
      global.localStorage.microsoftOAuthNonce = nonce
      window.location = this.authUrl(nonce)
    }
  }

  authUrl = (state) => {
    // TODO: get these values from some configuration
    let authorizationUrl = process.env.REACT_APP_AZURE_AUTHORIZATION_URL
    let clientId = process.env.REACT_APP_AZURE_CLIENT_ID
    let scope = "user.read"
    let authUrl = `${authorizationUrl}?response_type=code&client_id=${clientId}&redirect_uri=${this.redirectUri()}&state=${state}&scope=${scope}`
    return authUrl
  }

  redirectUri = () => {
    let url = new URL(window.location)
    return `${url.origin}/microsoft/callback`
  }
}

const MicrosoftServiceInstance = new MicrosoftService()
export { MicrosoftServiceInstance as MicrosoftService }