import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { SessionStatus } from '../../constants'
import { TokenActions } from 'actionsets'
import * as Containers from 'containers'
import { withRouter } from 'react-router-dom'
import { throttle } from 'utils'
import { Routes, Nav } from '.'
import CssBaseline from '@material-ui/core/CssBaseline'
import withStyles from 'styles'

export class App extends Component{

  static activityEventTypes = ['mousemove', 'click', 'keydown', 'resize']

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    this.state = {
      ready: false
    }
    this.initialize()
  }

  initialize = async () => {
    await this.actions.registerActivity()
    await this.actions.verify(true)
    this.setState({ready: true})
  }

  renderRoutes = () => {
    switch(this.props.loginState){
    case SessionStatus.UNKNOWN:         { return <Routes.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Routes.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Routes.Unauthenticated/> }
    default: { return false }
    }
  }

  renderNav = () => {
    switch(this.props.loginState){
    case SessionStatus.UNKNOWN:         { return <Nav.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Nav.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Nav.Unauthenticated/> }
    default: { return false }
    }
  }

  registerActivity = throttle(() => {
    this.actions.registerActivity()
  })

  monitorActivity = container => {
    if(this.container){
      App.activityEventTypes.forEach(type => this.container.removeEventListener(type, this.registerActivity))
    }
    this.container = container
    if(this.container){
      App.activityEventTypes.forEach(type => this.container.addEventListener(type, this.registerActivity))
    }
  }

  render = () => {
    return this.state.ready ?
      <Fragment>
        <CssBaseline />
        {this.renderNav()}
        <div className={this.props.classes.contentWrapper} ref={this.monitorActivity}>{this.renderRoutes()}</div>
        <Containers.Shared.Snackbar/>
      </Fragment> : null
  }
}


const styles = theme => ({
  contentWrapper: {
    flex: '1 0 auto',
    display: 'flex',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      //IE ONLY
      minHeight: 'calc(100vh - 130px)'
    },
    '& > div': {
      flex: '1 0 auto',      
      maxWidth: '100%'
    }
  }
})

export default withRouter(connect(({tokens}) => tokens)(withStyles(styles)(App)))