import { TokenActions } from 'actionsets'
import { TokenStore } from 'services'

export default function authenticationMiddleware() {
  return (next) => (action) => {
    try{
      if(action.payload.error.status === 401 && TokenStore.auth && action.type !== TokenActions.VERIFY_FAILURE){
        return next(
          dispatch => dispatch(TokenActions.verify())
        ).then(()  => next(action))
         .catch(() => next(dispatch => dispatch(TokenActions.destroy())))
      }
    }catch(_){ /* pass */}
    return next(action)
  }
}