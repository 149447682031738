import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class FundCategoryActionSet extends CrudActionSetBase('fundCategory', {
  apiResource: API['FundCategories'],
  term: {
    plural: 'fundCategories',
    singular: 'fundCategory'
  }
}){}
export default new FundCategoryActionSet()