import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FundBenchmarkActions, IndexActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import { FormContext, CommentsList } from 'components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { compose, formatValue } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { humanize, titleize, indexBy, Authorization } from 'utils'

export class Composition extends Component {

  constructor(props) {
    super(props)
    FundBenchmarkActions.bindActions(this)
    IndexActions.bindActions(this, 'indices')
    this.state = {}
  }

  dependsOn() {
    const benchmarkAction = this.actions.show(this.props.match.params.id, { include: 'comments,comments.user' })
    const indicesAction = this.actions.indices.index({
      page: 1, pageSize: 2000, fields: { indices: 'id,code,name' }, order: 'code',
    })
    const benchmarksAction = this.actions.index({
      page: 1, pageSize: 2000, fields: { fundBenchmarks: 'id,name,shortName' }, order: 'shortName',
    })
    return Promise.all([benchmarkAction, indicesAction, benchmarksAction])
  }

  dependenciesMet() {
    return !!this.props.fundBenchmark
  }

  get id() {
    return this.props.match.params.id
  }

  get fundBenchmark() {
    return this.props.fundBenchmark
  }


  buildLookups = (prevProps) => {
    if (this.props.indices !== prevProps.indices) {
      this.setState({ indicesById: indexBy(this.props.indices, 'id') })
    }
    if (this.props.fundBenchmarks !== prevProps.fundBenchmarks) {
      this.setState({ benchmarksById: indexBy(this.props.fundBenchmarks, 'id') })
    }
  }

  componentDidMount = () => {
    this.buildLookups({})
  }

  componentDidUpdate = (prevProps) => {
    this.buildLookups(prevProps)
  }

  renderRow = (field, label, format = x => x) => <tr>
    <th className={this.props.classes.alignRight}>
      {label || humanize(field.replace(/^.*(?:\[(?=\d)|(?!=\d)\]|\.)(.*)$/, "$1"))}:
    </th>
    <td>{format(this.fundBenchmark[field])}</td>
  </tr>

  renderLabel = (text) => <div className={this.props.classes.label}>
    {text}:
  </div>

  renderEmpty = () => <span>&#8203;</span>

  render = () =>
    <PageContainer width="lg">
      <ActionHeader title={`Benchmark - ${this.fundBenchmark.name || ''}`}>
        <Button color="primary" variant="contained" onClick={() => this.props.history.push(`/fund_benchmarks/${this.id}`)}>Data</Button>
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push(`/fund_benchmarks/${this.id}/edit`)} style={{ marginLeft: 10 }}>Edit</Button>
        }
      </ActionHeader>
      <FormContext context={this.fundBenchmark} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        <CardContent>
          <table>
            <tbody>
              {this.renderRow('name')}
              {this.renderRow('shortName')}
              {this.renderRow('currency')}
              {this.renderRow('initialLevel')}
              {this.renderRow('benchmarkType', 'Benchmark Type', titleize)}
            </tbody>
          </table>

          {!!this.fundBenchmark.benchmarkType && <div className={this.props.classes.weightingsDiv}>
            <ActionHeader title="Weightings" />
            {(this.fundBenchmark.weightingGroups || []).map(group => <Card className="weightingsCard" key={group.key}>
              <CardContent>
                <table className={this.props.classes.weightingTable}>
                  <tbody>
                    <tr>
                      <td colSpan="2">{this.renderLabel('Effective')}{formatValue('date', group.effective)}</td>
                      <td colSpan="2">{this.renderLabel('Initial Return')}{formatValue('%', group.initial_value, 10) || this.empty}</td>
                    </tr>
                    {group.weightings.map(weighting => <tr key={weighting.key}>
                      {weighting.weightable_type === "Index" && <td className="weightable">
                        {this.renderLabel('Index')}
                        <div>
                          <span className={this.props.classes.indexCode}>{this.state.indicesById?.[`${weighting.weightable_id}`]?.code}</span>
                          {this.state.indicesById?.[`${weighting.weightable_id}`]?.name}
                        </div>
                      </td>}
                      {weighting.weightable_type === "FundBenchmark" && <td className="weightable">
                        {this.renderLabel('Benchmark')}
                        <div>
                          <span className={this.props.classes.indexCode}>{this.state.benchmarksById?.[`${weighting.weightable_id}`]?.shortName}</span>
                          {this.state.benchmarksById?.[`${weighting.weightable_id}`]?.name}
                        </div>
                      </td>}
                      <td colSpan={this.fundBenchmark.benchmarkType === "multi-asset" ? 3 : 1}>
                        {this.renderLabel('Split')}{formatValue('%', weighting.percentage, 0) || this.renderEmpty()}
                      </td>
                      {this.fundBenchmark.benchmarkType !== "multi-asset" && <>
                        <td colSpan={this.fundBenchmark.benchmarkType === "multi-asset" ? 3 : 1}>
                          {this.renderLabel('Hedge')}{formatValue('%', weighting.hedging_rate, 2) || this.renderEmpty()}
                        </td>
                        <td colSpan={this.fundBenchmark.benchmarkType === "multi-asset" ? 3 : 1}>
                          {this.renderLabel('Hurdle')}{formatValue('%', weighting.hurdle, 2) || this.renderEmpty()}
                        </td>
                      </>}
                    </tr>)}
                  </tbody>
                </table>
              </CardContent>
            </Card>)}
          </div>}
        </CardContent>
      </FormContext>
      <CardContent>
        <ActionHeader title="Comments" />
        <CommentsList comments={this.fundBenchmark.comments} />
      </CardContent>
    </PageContainer>
}

const styles = {
  label: {
    fontSize: '0.8em',
    color: 'gray',
  },
  alignRight: {
    textAlign: 'right'
  },
  weightingsDiv: {
    marginTop: 30,
    '& .weightingsCard': {
      marginTop: 10,
    },
  },
  indexCode: {
    margin: "0 5px 0 0",
    display: "inline-block",
    fontWeight: "bold",
    background: "#dbdbdb",
    padding: '0 5px 0 5px',
    textAlign: "center",
    borderRadius: "14px",
    lineHeight: "1.6",
    paddingTop: "1px",
  },
  weightingTable: {
    '& td': {
      paddingRight: 10,
      whiteSpace: 'nowrap',
      minWidth: 80,
      maxWidth: 120,
      borderBottom: 'solid 1px #EEE',
      paddingTop: 5,
      paddingBottom: 5,
    },
    '& td:last-child': {
      paddingRight: 0,
      minWidth: 'auto',
    },
    '& td.weightable': {
      width: '99%',
      overflowX: 'hidden',
    },
  }
}

export default compose(
  Dependent({ loader: true }),
  withStyles(styles),
  connect(({ fundBenchmarks }) => fundBenchmarks),
  connect(({ indices }) => indices),
)(Composition)