import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'
import React, { Component, Fragment } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { ConfirmationDialog } from 'components'

class HelpButton extends Component {
  state = {
    openDialog: false
  }

  handleOpen = () => {
    this.setState({ openDialog: true })
  }

  handleClose = () => {
    this.setState({ openDialog: false })
  }

  render = () => {
    const {children, ...props} = this.props
    return (
      <Fragment>
      	<Tooltip title='More info'>
        	<IconButton {...props} onClick={this.handleOpen} color='primary'>
          	<HelpIcon />
        	</IconButton>
      	</Tooltip>
      	<ConfirmationDialog
         	title='Info'
         	open={this.state.openDialog}
         	onConfirm={this.handleClose}
         	confirmLabel={'Close'}
      	>
       		{children}
      	</ConfirmationDialog>
      </Fragment>
    )
  }
}

export default HelpButton
