import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { UserActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, Select }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { compose } from 'utils'
import withStyles from 'styles'
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Authorization } from 'utils'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import {  LabeledCheckbox } from 'components'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    UserActions.bindActions(this)
  }

  get formObject(){
    return {...this.props.user, ...this.state.formAttributes}
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Edit User - ${this.formObject.name || ''}`}/>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <TextField fullWidth member='email'/>
          <Select disabled={this.editMode && !Authorization.admin} fullWidth member='role'>
            <MenuItem value='member'>Member</MenuItem>
            <MenuItem value='admin'>Admin</MenuItem>
          </Select>
          {
            (this.createMode) &&
            <FormControlLabel control={
              <FormContext onChange={this.handleFormObjectChange} context={this.formObject}>
                <Checkbox member='skipInvite'/>
              </FormContext>} label="Skip Invite"
            />
          }
          <br/>
          {
            ((this.editMode && Authorization.user.id === this.props.user.id) || this.formObject.skipInvite) &&
            <CardContent className={this.props.classes.passwords}>
              <Typography variant='body1'>Password {this.editMode && '(Optional)'}</Typography>
              {
                Authorization.user.id === this.props.user.id &&
                <TextField  type="password" fullWidth member="oldPassword"/>
              }
              <TextField type='password' fullWidth member='password'/>
              <TextField disabled={!this.formObject.password} type='password' fullWidth member='passwordConfirmation'/>
            </CardContent>
          }
          <LabeledCheckbox member='receiveIntraMonthPerformance'/>

        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </PageContainer>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  passwords: {
    marginTop: 5,
    background: theme.palette.grey[100]
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({users}) => users),
)(Form)