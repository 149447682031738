import React, { Component } from 'react'
import { connect }          from 'react-redux'
import {NotificationActions, UserActions} from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import {FormContext, Select} from 'components'
import PageContainer        from 'components/PageContainer'
import LoadedHeader         from 'components/LoadedHeader'
import LoadedContent        from 'components/LoadedContent'
import TextField            from '@material-ui/core/TextField'
import Button               from '@material-ui/core/Button'
import Dialog               from '@material-ui/core/Dialog'
import DialogTitle          from '@material-ui/core/DialogTitle'
import DialogContent        from '@material-ui/core/DialogContent'
import DialogActions        from '@material-ui/core/DialogActions'
import Radio                from '@material-ui/core/Radio'
import RadioGroup           from '@material-ui/core/RadioGroup'
import FormControlLabel     from '@material-ui/core/FormControlLabel';
import FormLabel            from '@material-ui/core/FormLabel'
import { compose, humanize }          from 'utils'
import withStyles           from 'styles'
import MenuItem             from '@material-ui/core/MenuItem'
import PromiseButton from 'components/PromiseButton'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    NotificationActions.bindActions(this)
    UserActions.bindActions(this, 'users')
  }

  state = {
    recipientsDialogOpen: false,
    formAttributes: {
      recipients: [],
      delivery: 'app'
    }
  }

  async dependsOn() {
    await Promise.all(
      [
        this.actions.users.index(),
        super.dependsOn()
      ]
    )
  }

  get formObject(){
    return {...this.props.notification, ...this.state.formAttributes}
  }

  get nonDirectUserRecipients() {
    return ['all', 'all_admins', 'all_members']
  }

  get recipientsList() {
    let recipientData = this.state.formAttributes.recipients
    return recipientData.map((recipient) => {
      if(this.nonDirectUserRecipients.includes(recipient)) {
        return humanize(recipient)
      } else {
        return this.props.users.find((user) => user.id === recipient).email
      }
    })
  }

  handleRecipientsClick = () => {
    this.setState({recipientsDialogOpen: true})
  }

  handleRecipientsClose = () => {
    this.setState({recipientsDialogOpen: false})
  }

  handleRecipientsOk = () => {
    this.setState({recipientsDialogOpen: false})
  }

  handleRecipientChange = (event) => {
    let recipients = event.target.value
    let newFormState;
    if(this.nonDirectUserRecipients.includes(recipients[recipients.length - 1])){
      newFormState = {...this.state.formAttributes, ...{recipients: [recipients.pop()]}}
      this.setState({recipientsDialogOpen: false})
    } else {
      newFormState = {...this.state.formAttributes, ...{recipients: recipients.filter((r) => !this.nonDirectUserRecipients.includes(r))}}
    }
    this.setState({formAttributes: newFormState})
  }

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>
        {this.editMode ? `Edit Notification` : 'New Notification'}
      </LoadedHeader>
      <LoadedContent>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
          {this.renderErrorMessages()}
          <Dialog
            open={this.state.recipientsDialogOpen}
            aria-labelledby="form-dialog-title"
          >
          <DialogTitle id="form-dialog-title">Select Recipients</DialogTitle>
          <DialogContent>
            <Select
              multiple
              fullWidth
              onChange={this.handleRecipientChange}
              value={this.state.formAttributes.recipients}
              member='recipients'
              label='Recipients'
            >
              {this.nonDirectUserRecipients.map((recipient) => <MenuItem key={recipient} value={recipient}>{humanize(recipient)}</MenuItem>)}
              {this.props.users.map((user) => <MenuItem key={user.id} value={String(user.id)}>{user.email}</MenuItem>)}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRecipientsClose} color="primary">
              Ok
            </Button>
            <Button onClick={this.handleRecipientsClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
          <TextField member='title'/>
          <br />
          <TextField member='message' multiline={true} rows={5}/>
          <br />
          <FormLabel component="legend">Delivery</FormLabel>
          <RadioGroup member='delivery' label='Delivery'>
            <FormControlLabel value="app" control={<Radio />} label="In App" />
            <FormControlLabel value="email" control={<Radio />} label="In App + Email" />
          </RadioGroup>
          <br />
          <TextField disabled={true} member='recipients' value={this.recipientsList}/>
          <PromiseButton variant='contained' fullWidth onClick={this.handleRecipientsClick}>Select recipients</PromiseButton>
          <br/>
          <PromiseButton disabled={this.state.formAttributes.recipients.length === 0} color='secondary' fullWidth variant='contained' type='submit'>Send</PromiseButton>
        </FormContext>
      </LoadedContent>
    </PageContainer>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({notifications, users}) => ({...users, ...notifications})),
)(Form)

