import React from 'react'
import { Redirect, Route as BasicRoute } from 'react-router-dom'

const performTest = test =>{
  if(test === undefined){
    return true
  }

  if(!test){
    return false
  }
  if(typeof test === 'function'){
    return !!test()
  }
  return true
}
export const Route = props => {
  const { requires, onUnauthorized, ...rest } = props
  if (performTest(requires))
    return <BasicRoute {...rest}/>
  else
    return <Redirect to={onUnauthorized || "/unauthorized"}/>
}