import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FundBenchmarkActions, BenchmarkPointActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { Pagination, CommentsList } from 'components'
import Button from '@material-ui/core/Button'
import DatePicker from 'components/DatePicker'
import { compose, formatValue } from 'utils'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { connectQueryString } from 'containers/shared'
import { submitForm } from 'services/APIResource'
import withStyles from 'styles'

export class Show extends Component {

  constructor(props) {
    super(props)
    FundBenchmarkActions.bindActions(this)
    BenchmarkPointActions.bindActions(this, 'benchmarkPoint')
  }

  state = {}

  get id() {
    return this.props.match.params.id
  }

  get fundBenchmark(){
    return this.props.fundBenchmark
  }

  get filter() {
    return { fundBenchmarkId: this.id, granularity: this.granularity, ...(this.fromDate && { fromDate: this.fromDate }), ...(this.toDate && { toDate: this.toDate }) }
  }

  loadIndexPoints = () => {
    return this.actions.benchmarkPoint.index({
      pageSize: 16,
      page: this.props.page,
      order: this.sortDir === 'asc' ? 'date' : '-date',
      filter: this.filter,
    })
  }

  handleExportFile = () => {
    const data = {
      Authorization: global.localStorage.auth,
      order: this.sortDir === 'asc' ? 'date' : '-date',
      filter: this.filter,
    }
    submitForm(`/api/benchmark_points.xlsx`, data, 'post')
  }

  dependsOn() {
    return Promise.all([
      this.actions.show(this.id, { include: 'comments,comments.user' }),
      this.loadIndexPoints()
    ])
  }

  dependenciesMet() {
    return !!this.props.fundBenchmark && !!this.props.benchmarkPoints
  }

  handleFilterChange = (changes, callback) => {
    this.props.onFilterChange({ ...this.props.filter, ...changes }, callback)
  }

  handleDateChange = (field) => ({ target: { value } }) => {
    this.handleFilterChange({ [field]: ((value && value.length > 9) ? value.substring(0, 10) : value) }, this.loadIndexPoints)
  }

  get fromDate() {
    return this.props.filter.fromDate || ""
  }

  get toDate() {
    return this.props.filter.toDate || ""
  }

  get granularity() {
    return this.props.filter.granularity || 'monthly'
  }

  get sortDir() {
    return this.props.filter.sortDir || 'desc'
  }

  handleToggleSortOrder = () => {
    this.handleFilterChange({ sortDir: this.sortDir === 'asc' ? 'desc' : 'asc' }, this.loadIndexPoints)
  }

  get benchmarkPoints() {
    return this.props.benchmarkPoints
  }

  handlePageSelected = async page => {
    await this.props.onPageChange(page)
    this.loadIndexPoints()
  }

  handleToggleGranularity = () => {
    const granularity = (this.granularity === 'monthly' ? 'daily' : 'monthly')
    this.handleFilterChange({ granularity }, this.loadIndexPoints)
  }

  render = () => {
    return <div className={this.props.classes.wrapper}>
      <PageContainer>
        <ActionHeader title={`Benchmark Data - ${this.props.fundBenchmark.name}`} style={{ marginRight: 16 }}>
          <Button color="primary" variant="contained" onClick={this.handleToggleGranularity} style={{ marginRight: 5 }}>
            {this.granularity === 'monthly' ? "Show Daily" : "Show Monthly"}
          </Button>
          <Button color="secondary" style={{ color: 'white' }} variant="contained" onClick={this.handleExportFile}>Export&emsp;<DownloadIcon /></Button>
        </ActionHeader>
        <div>
          Short Name: {this.props.fundBenchmark.shortName}
        </div>
        <DatePicker clearable label="From Date" onChange={this.handleDateChange('fromDate')} value={this.fromDate} style={{ marginRight: 10 }} />
        <DatePicker clearable label="To Date" onChange={this.handleDateChange('toDate')} value={this.toDate} />
        <Pagination totalPages={this.props.benchmarkPointsTotalPages || 1} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <div className={this.props.classes.containerDiv}>
          <Table>
            <TableBody>
              <TableRow className="header top-labels">
                <TableCell className={this.props.classes.headCol}>
                  <b>
                    Date
                    <IconButton size='small' style={{ padding: 5, top: 7, marginTop: -15 }} onClick={this.handleToggleSortOrder}>
                      {this.sortDir === 'asc' ? <ArrowUp size='small' /> : <ArrowDown size='small' />}
                    </IconButton>
                  </b>
                </TableCell>
                <TableCell><b>Percentage Return</b></TableCell>
                <TableCell><b>Final Level</b></TableCell>
              </TableRow>
              {this.benchmarkPoints.map((benchmarkPoint, idx) => {
                return <Fragment key={benchmarkPoint.id}>
                  <TableRow>
                    <TableCell className={this.props.classes.headCol}>
                      {benchmarkPoint.date}
                    </TableCell>
                    <TableCell>
                      {formatValue('%', benchmarkPoint.percentageReturnN, 2)}
                    </TableCell>
                    <TableCell>
                      {formatValue('#', benchmarkPoint.finalLevel, 2)}
                    </TableCell>
                  </TableRow>
                </Fragment>
              })}
              {this.benchmarkPoints.length === 0 && <TableRow>
                <TableCell colSpan={3}>{"No data found for the selected dates"}</TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </div>
        <Pagination totalPages={this.props.benchmarkPointsTotalPages || 1} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <br />
        <ActionHeader title="Comments" />
        <CommentsList comments={this.fundBenchmark.comments} readonly={true} />
      </PageContainer>
    </div>
  }
}

const styles = theme => ({
  headCol: {
    position: "absolute",
    width: 150,
    paddingTop: 12,
    left: "0",
    top: "auto",
    padding: 0,
    margin: 0,
    height: 40,
    background: '#D3DCE0',
    '& > b': {
      paddingTop: 0
    }
  },
  containerDiv: {
    overflowX: 'scroll',
    marginLeft: 150,
    marginTop: 4,
    '& .top-labels': {
      height: 30,
      '& $headCol': {
        height: 30,
      }
    },
    '& .header': {
      background: theme.palette.secondary.light
    },
    '& tr': {
      height: '40px',
      padding: 0,
      margin: 0,
      '&:hover': {
        '& $headCol': {
          background: theme.palette.primary.light,
          fontWeight: 'bold'
        },
        background: theme.palette.primary.light,
        '& td:hover': {
          background: theme.palette.primary.main,
          color: 'white',
          cursor: 'pointer'
        }
      }
    },
    '& tr.header td': {
      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
    },
    '& td': {
      whiteSpace: 'nowrap',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& td.negative': {
      color: 'red',
    }
  },
  wrapper: {
    '& > div': {
      width: '85%',
      maxWidth: 4000,
    }
  },
})

export default compose(
  Dependent({ loader: true, clearOnLoad: true }),
  withStyles(styles),
  connectQueryString('benchmarkPoints'),
  connect(({ fundBenchmarks }) => fundBenchmarks),
  connect(({ benchmarkPoints }) => benchmarkPoints),
)(Show)