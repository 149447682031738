import React, {Component} from 'react'
import withStyles from 'styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {NotificationActions} from 'actionsets'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

export class NotificationsPopup extends Component {

  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)
    this.state = { notifications: [], unread: 0 }
  }

  get notifications() {
    return this.state.notifications
  }

  componentDidMount = () => {
    this._mounted = true
    this.pollForNotifications(0)
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  pollForNotifications = (wait = 30 * 1000) => {
    window.setTimeout(async() => {
      if (this._mounted) {
        await this.loadNotifications()
        this.pollForNotifications()
      }
    }, wait)
  }

  loadNotifications = async () => {
    const { data: notifications, meta: { unread } } = await this.actions.fetchFirstPage()
    this.setState({notifications, unread})
  }

  componentDidUpdate = prevProps => {
    if (this.props.show && !prevProps.show) {
      this.loadNotifications()
    }
  }

  linkToAction = notification => () => {
    this.actions.update({...notification, read: true}, { include: 'user'})
    if(notification.linkTo)
      this.props.history.push('/' + notification.linkTo)
  }

  render = () => {
    const {classes, show} = this.props
    return (
      <div className={classes({wrapper: true, hidden: !show})} onClick={this.props.onClose}>
        <div className={classes.popup} onClick={e => e.stopPropagation()}>
          {this.notifications.length === 0 &&
            <Typography className={classes.emptyMessage}>Your inbox is empty</Typography>
          }
          <List className={classes.notificationList}>
            {this.notifications.map(notification =>
              <ListItem key={notification.id} button classes={{root: classes({notification: true, read: notification.read})}} onClick={this.linkToAction(notification)}>
                <ListItemText
                  primary={notification.title}
                  secondary={notification.message}
                  classes={{root: classes.notificationText, primary: classes.title, secondary: classes.message}}
                />
                <span className={classes.date}>{moment(notification.createdAt).format('DD/MM/YY')}</span>
              </ListItem>
            )}
          </List>
          <div className={classes.footer}>
            <Link to="/notifications/mine" className={classes.more}>more...</Link>
          </div>
        </div>
      </div>
    )
  }
}

const styles = ({palette}) => ({
  wrapper: {
    position: 'fixed',
    top: 50,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1200,
    background: 'transparent',
    overflow: 'hidden',
    '&.hidden': {
      pointerEvents: 'none',
      '& $popup': {
        transform: 'translate(0, -100%)',
        boxShadow: 'none'
      },
    }
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
    '@media(min-width: 600px)': {
      width: 342,
      height: 410,
      borderLeft: '1px solid rgba(255,255,255,0.5)',
      borderBottom: '1px solid rgba(255,255,255,0.5)',
    },
    transition: 'transform 225ms, box-shadow 225ms',
    boxShadow: '13px 10px 50px 18px rgba(0,0,0,0.1)',
  },
  notificationList:{
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: 0,
    marginBottom: 15
  },
  notification: {
    '&:last-child': {
      borderBottom: '1px solid rgba(255,255,255,0.3)'
    },
    position: 'relative'
  },
  notificationText: {
    padding: 0,
  },
  title: {
    'span&': {
      // color: '#FFF',
      fontWeight: 'bold',
      fontSize: '1.125rem',
      margin: '5px 60px 5px 0',
      lineHeight: 1.1,
    },
  },
  message: {
    // color: '#FFF',
    fontSize: '1rem',
    margin: 0,
    lineHeight: 1.2,
    paddingBottom: 3,
  },
  more: {
    color: '#fff !important',
    textDecoration: 'underline !important'
  },
  date: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#666',
    position: 'absolute',
    top: 15,
    right: 10,
  },
  read: {
    background: '#e9e9e9',
    '& $notificationText, $date': {
      opacity: 0.7
    },
    '& span$title, $message, $date': {
      color: '#909090',
    },
  },
  footer: {
    flex: '0 0 40px',
    marginTop: -15,
    paddingTop: 15,
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
    background: palette.primary.light,
    zIndex: 100,
  },
  emptyMessage: {
    color: 'white',
    padding: 20,
  }
})

export default connect(({notifications}) => notifications)(withRouter(withStyles(styles)(NotificationsPopup)))
