import CrudActionSetBase from './CrudActionSetBase'
import {Notifications} from 'api'

export class NotificationActionSet extends CrudActionSetBase('notification'){
  static initialState = {
    page:        1,
    totalPages:  1,
    mine:          [],
    notifications: [],
    notification:  {},
    requests:    [],
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null,
      show:    null,
      mine:    null,
      fetchFirstPage:  null,
    }
  }

  static mine(creator, reducer, constants){

    constants.defineRequestConstants()
    creator(({page=1, pageSize=10}={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: Notifications.mine({options: {page: { number: page, size: pageSize }}}),
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { unread, totalPages: totalPagesMine, page: pageMine }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, mine: data, unread, totalPagesMine, pageMine }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, mine: error }
        return {...state, requests, errors }
      }
    })
  }


  static fetchFirstPage(creator, reducer, constants){
    constants.defineRequestConstants()
    creator(() => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: Notifications.mine({options: {page: { number: 1, size: 10 }}}),
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { meta: { unread }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, unread }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, fetchFirstPage: error }
        return {...state, requests, errors }
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((item, options={}) => {
      return {
        type: constants.ACTION,
        promise: Notifications.update({...item, options})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, update: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: {unread}={} } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const notifications = state.notifications.map(r => r.id === data.id ? data : r)
        const mine = state.mine.map(r => r.id === data.id ? data : r)
        return {...state, requests, notification: data, notifications, mine, unread}
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, update: error }
        return {...state, requests, errors }
      }
    })
  }

  static resend(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((item, options={}) => {
      return {
        type: constants.ACTION,
        promise: Notifications.resend({...item, options})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, update: null }}
      },
      [constants.SUCCESS]: (state, { request, result }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      }
    })
  }
}
export default new NotificationActionSet()