import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FundCategoryActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FundCategoryIcon from '@material-ui/icons/Label'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { Authorization } from 'utils'

export class List extends Component{

  constructor(props){
    super(props)
    FundCategoryActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadFundCategories()
  }

  loadFundCategories = () => {
    return this.actions.index({
      page: this.props.page,
      fields: {fund_categories: 'name'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showFundCategory = id => () => {
    this.props.history.push(`/fund_categories/${id}`)
  }

  editFundCategory = id => event => {
    this.props.history.push(`/fund_categories/${id}/edit`)
    event.stopPropagation()
  }

  deleteFundCategory = id => event => {
    this.actions.destroy({id})
                .then(this.loadFundCategories)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get fundCategories(){
    return this.props.fundCategories
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadFundCategories()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderFundCategoryListItem = ({id, name}) =>
    <ListItem button onClick={this.showFundCategory(id)} key={id}>
      <ListItemIcon>
        <FundCategoryIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        {
          Authorization.admin &&
          <>
            <IconButton onClick={this.editFundCategory(id)}><EditIcon/></IconButton>
            <IconButton onClick={this.deleteFundCategory(id)}><DeleteIcon/></IconButton>
          </>
        }
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <ActionHeader title="Fund Categories">
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push('/fund_categories/new')}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.fundCategories.map(this.renderFundCategoryListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('fundCategories'),
  connect(({fundCategories}) => fundCategories)
)(List)